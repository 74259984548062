import { createApi } from '@reduxjs/toolkit/query/react';
import { ICampaignAPI, ICampaignFetchAPI, PaymentStatus, Vads } from '../models/campaign';
import { baseQueryWithReauth } from './BaseQuery';

export const campaignApi = createApi({
  reducerPath: 'campaignApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Campaign'],
  endpoints: (builder) => ({
    getCampaigns: builder.query<
      { paid: ICampaignFetchAPI[]; pending: ICampaignFetchAPI[]; closed: ICampaignFetchAPI[] },
      void
    >({
      query: () => 'campaign/',
      transformResponse: (resp: ICampaignFetchAPI[]) => {
        const campaignsClosed = resp
          .filter((campaign) => campaign.closedAt)
          .sort(function (a, b) {
            // had to add "as string" because ts still think it can be undefined even after filter
            return new Date(b.closedAt as string).valueOf() - new Date(a.closedAt as string).valueOf();
          });

        const campaignsPaid = resp
          .filter((campaign) => campaign.paymentStatus === PaymentStatus.PAID && !campaign.closedAt)
          .sort(function (a, b) {
            return new Date(a.startDatetime).valueOf() - new Date(b.startDatetime).valueOf();
          });

        const campaignsPending = resp
          .filter((campaign) => campaign.paymentStatus !== PaymentStatus.PAID)
          .sort(function (a, b) {
            return new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf();
          });
        const campaigns = { paid: campaignsPaid, pending: campaignsPending, closed: campaignsClosed };

        return campaigns;
      },
      providesTags: ['Campaign'],
    }),

    getCampaignVads: builder.query<{ vads: Vads }, string>({
      query: (id) => `onboarding/vads/${id}/`,
    }),

    createCampaign: builder.mutation<{ vads: Vads; campaign: ICampaignAPI }, { form: ICampaignAPI }>({
      query: (body) => ({
        url: '/onboarding/create_campaign/',
        method: 'POST',
        body: body.form,
      }),
      invalidatesTags: ['Campaign'],
    }),

    // updateCampaign: builder.mutation({
    //   query: (body: { form: ICampaignAPI }) => ({
    //     url: `/onboarding/${body.form.id}/update_campaign/`,
    //     method: 'PUT',
    //     body: body.form,
    //   }),
    //   invalidatesTags: ['Campaign'],
    // }),
    // deleteCampaign: builder.mutation({
    //   query: (params: { id: string }) => {
    //     return {
    //       url: `/campaign/${params.id}/`,
    //       method: 'DELETE',
    //     };
    //   },
    //   invalidatesTags: ['Campaign'],
    // }),

    uploadMedia: builder.mutation({
      query: (body: { form: FormData }) => {
        return {
          url: '/uploaded_media/',
          method: 'POST',
          body: body.form,
        };
      },
    }),
  }),
});

export const { useCreateCampaignMutation, useUploadMediaMutation, useGetCampaignsQuery, useLazyGetCampaignVadsQuery } =
  campaignApi;
export const { endpoints, reducerPath, reducer, middleware } = campaignApi;
