import { createApi } from '@reduxjs/toolkit/query/react';
import { IMatchAPI } from '../models/match';
import { baseQueryWithReauth } from './BaseQuery';

interface MatchesParams {
  start_date?: string;
  end_date?: string;
}

export const matchApi = createApi({
  reducerPath: 'matchApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getMatchs: builder.query<IMatchAPI[], MatchesParams>({
      query: (params) =>
        `match/${params.start_date ? '?start_date=' + params.start_date : ''}${
          params.end_date ? '&end_date=' + params.end_date : ''
        }`,
      transformResponse: (resp: IMatchAPI[]) => {
        const matches = resp.sort((m1, m2) => {
          const m1BeginDatetime = new Date(m1.beginDatetime);
          const m1BeginDate = new Date(
            m1BeginDatetime.getFullYear(),
            m1BeginDatetime.getMonth(),
            m1BeginDatetime.getDate(),
          );
          const m2BeginDatetime = new Date(m2.beginDatetime);
          const m2BeginDate = new Date(
            m2BeginDatetime.getFullYear(),
            m2BeginDatetime.getMonth(),
            m2BeginDatetime.getDate(),
          );
          if (m1BeginDate > m2BeginDate) {
            return 1;
          } else if (m1BeginDate < m2BeginDate) {
            return -1;
          } else {
            return m1.sport > m2.sport ? 1 : -1;
          }
        });
        return matches;
      },
    }),
  }),
});

export const { useGetMatchsQuery } = matchApi;
export const { endpoints, reducerPath, reducer, middleware } = matchApi;
