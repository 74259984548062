import { configureStore } from '@reduxjs/toolkit';
import campaignReducer from './slice/CampaignSlice';
import { middleware, userApi } from './services/UserApi';
import tokenReducer from './slice/TokenSlice';
import { campaignApi } from './services/CampaignApi';
import { couponApi } from './services/CouponApi';
import { matchApi } from './services/MatchApi';
import { clubApi } from './services/ClubApi';
import { sportApi } from './services/SportApi';
import { tokenApi } from './services/TokenApi';
import { creditApi } from './services/CreditApi';
import { resetPasswordApi } from './services/ResetPasswordApi';
import { tpInventaryApi } from './services/TpInventaryApi';

export const store = configureStore({
  reducer: {
    campaign: campaignReducer,
    token: tokenReducer,
    [userApi.reducerPath]: userApi.reducer,
    [resetPasswordApi.reducerPath]: resetPasswordApi.reducer,
    [tokenApi.reducerPath]: tokenApi.reducer,
    [campaignApi.reducerPath]: campaignApi.reducer,
    [matchApi.reducerPath]: matchApi.reducer,
    [clubApi.reducerPath]: clubApi.reducer,
    [sportApi.reducerPath]: sportApi.reducer,
    [couponApi.reducerPath]: couponApi.reducer,
    [creditApi.reducerPath]: creditApi.reducer,
    [tpInventaryApi.reducerPath]: tpInventaryApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(middleware)
      .concat(userApi.middleware)
      .concat(resetPasswordApi.middleware)
      .concat(tokenApi.middleware)
      .concat(campaignApi.middleware)
      .concat(matchApi.middleware)
      .concat(clubApi.middleware)
      .concat(sportApi.middleware)
      .concat(couponApi.middleware)
      .concat(creditApi.middleware)
      .concat(tpInventaryApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
