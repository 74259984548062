import { createApi } from '@reduxjs/toolkit/query/react';
import { Coupon } from '../models/coupon';
import { baseQueryWithReauth } from './BaseQuery';

export const couponApi = createApi({
  reducerPath: 'couponApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getCoupon: builder.query<Coupon, string>({
      query: (code) => `/coupon/code/${code}/`,
    }),
  }),
});

export const { useLazyGetCouponQuery } = couponApi;

export const { endpoints, reducerPath, reducer, middleware } = couponApi;
