import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import jwt_decode from 'jwt-decode';
import { DecodeToken, ITokenRedux } from '../slice/TokenSlice';

const HasValidToken = () => {
  const token: ITokenRedux = useSelector((state: RootState) => state.token);

  if (!token || !token.access) {
    return false;
  } else {
    const decodeToken: DecodeToken = jwt_decode(token.access);

    // Date.now() return a timestamp with ms, and token.exp without
    const timestampNow = Math.floor(Date.now() / 1000);
    // 1698223511 token.exp
    // 1697709654337 Date.now()

    if (timestampNow > decodeToken.exp) {
      return false;
    } else return true;
  }
};

export default HasValidToken;
