import React, { useEffect, useState } from 'react';
import './Account.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useGetUserAccountQuery, useUpdateUserMailMutation, useUpdateAccountDataMutation } from '../services/UserApi';
import { AdvertiserAccount, userTypeEnum } from '../models/user';

import { clearToken } from '../slice/TokenSlice';

import LayoutPrincipal from '../component/LayoutPrincipal';
import MainInput from '../component/MainInput';
import MainButton from '../component/MainButton';
import MainRadio from '../component/MainRadio';
import MainModal from '../component/MainModal';
import { checkMailformat } from '../helper/emailHelper';
import { verifyZipcodes } from '../services/zipcodesService';
import { BrandColor } from '../helper/colorHelper';
import { isZipcodeFormat } from '../helper/zipcodeHelper';
import { isPhoneFormat } from '../helper/phoneHelper';

interface UpdateError {
  campaignManager?: string;
  companyName?: string;
  companyType?: string;
  phoneNumber?: string;
  address?: {
    country?: string;
    city?: string;
    zipcode?: string;
    street?: string;
  };
  general?: string;
}

export default function Account() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [accountData, setAccountData] = useState<AdvertiserAccount>();
  const [userMail, setUserMail] = useState<string>();

  const [accountError, setAccountError] = useState<UpdateError>();
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [showMailModal, setShowMailModal] = useState<boolean>(false);
  const [mailError, setMailError] = useState<string>();

  const userAccount = useGetUserAccountQuery();
  const [updateMail] = useUpdateUserMailMutation();
  const [updateAccount] = useUpdateAccountDataMutation();

  const handleAccountSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (accountData) {
      const isZipcode = isZipcodeFormat(accountData.address.zipcode)
        ? await verifyZipcodes(accountData.address.zipcode)
        : false;
      const isPhoneNumber = isPhoneFormat(accountData.phoneNumber);
      if (!isZipcode || !isPhoneNumber) {
        const updateErrors: UpdateError = {
          phoneNumber: !isPhoneNumber ? t('error.phone-format') : '',
          address: {
            zipcode: !isZipcode ? t('error.postal-code') : '',
          },
        };
        setAccountError(updateErrors);
        setIsUpdated(false);
      } else {
        const { error }: any = await updateAccount(accountData);
        if (error) {
          setIsUpdated(false);
          if (error.data?.advertiserAccount) {
            const accountError = error.data.advertiserAccount;
            const updateErrors: UpdateError = {
              companyType: accountError.companyType?.map((v: string) => t(v)).join(', ') || '',
              companyName: accountError.companyName?.map((v: string) => t(v)).join(', ') || '',
              campaignManager: accountError.companyName?.map((v: string) => t(v)).join(', ') || '',
              phoneNumber: accountError.phoneNumber?.map((v: string) => t(v)).join(', ') || '',
              address: {
                country: accountError.address?.country?.map((v: string) => t(v)).join(', ') || '',
                city: accountError.address?.city?.map((v: string) => t(v)).join(', ') || '',
                zipcode: accountError.address?.zipcode?.map((v: string) => t(v)).join(', ') || '',
                street: accountError.address?.street?.map((v: string) => t(v)).join(', ') || '',
              },
            };
            setAccountError(updateErrors);
          } else setAccountError({ general: t('error.general') });
        } else {
          setAccountError(undefined);
          setIsUpdated(true);
        }
      }
    }
  };

  const handleSubmitMail = async () => {
    if (userMail) {
      const { error }: any = await updateMail({ email: userMail });
      if (error) {
        const userError = error.data?.user;
        if (userError && !userError.general) {
          setMailError(userError?.email?.map((v: string) => t(v)).join(', ') || '');
        } else {
          setMailError(t('error.general'));
        }
        setShowMailModal(false);
      } else {
        dispatch(clearToken());
        return navigate('/login');
      }
    }
  };

  const verifyEmail = () => {
    if (userMail === userAccount.data?.user.email) {
      setMailError(t('error.same-email'));
    } else if (!userMail || checkMailformat(userMail)) {
      setMailError(t('error.email-format'));
    } else {
      setMailError('');
      setShowMailModal(true);
    }
  };

  useEffect(() => {
    if (userAccount.isSuccess && userAccount.data) {
      setAccountData(userAccount.data.advertiserAccount);
      setUserMail(userAccount.data.user.email);
    }
  }, [userAccount]);

  return (
    <LayoutPrincipal
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        flex: 1,
      }}
      isShowingVideoTuto={false}
    >
      <MainModal
        isOpen={showMailModal}
        handleClose={() => {
          setShowMailModal(false);
        }}
      >
        <p style={{ textAlign: 'center', margin: 0 }}>{t('modify-email')}</p>

        <MainButton label={t('validation')} onClickButton={() => handleSubmitMail()} />
      </MainModal>
      {accountData && userMail ? (
        <>
          <section className='accountColumns'>
            <p className='titleStep' style={{ marginBottom: '102px' }}>
              {t('identification')}
            </p>
            <div>
              <MainInput
                isDisabled={true}
                name='email'
                type='email'
                label={t('email')}
                value={userAccount.data?.user.email}
                width={'320px'}
              />
              <MainInput
                isRequired={true}
                name='newEmail'
                type='email'
                label={t('new') + ' ' + t('email')}
                width={'320px'}
                onChange={(e) => setUserMail(e.target.value)}
              />
              <MainButton label={t('save')} onClickButton={() => verifyEmail()} />
              {mailError && <p className='formError'>{mailError}</p>}
            </div>
          </section>

          <section className='accountColumns'>
            <p className='titleStep' style={{ marginBottom: '60px' }}>
              {t('account-info')}
            </p>
            <form
              onSubmit={(e) => {
                handleAccountSubmit(e);
              }}
            >
              <MainRadio
                label={t('status') + ' :'}
                name='companyType'
                value={accountData.companyType}
                choices={[
                  {
                    label: t('company'),
                    value: userTypeEnum.CIE,
                  },
                  {
                    label: t('asso'),
                    value: userTypeEnum.ASSO,
                  },
                ]}
                onChange={(e) => setAccountData({ ...accountData, companyType: e.target.value })}
              />
              {accountError && accountError.companyType && <p className='formError'>{accountError.companyType}</p>}

              <MainInput
                isRequired={true}
                name='companyName'
                label={t('new-account.company-name')}
                value={accountData.companyName}
                onChange={(e) => setAccountData({ ...accountData, companyName: e.target.value })}
              />
              {accountError && accountError.companyName && <p className='formError'>{accountError.companyName}</p>}

              <MainInput
                isRequired={true}
                name='siretSiren'
                label={t('new-account.siret')}
                value={accountData.siretSiren}
                isDisabled={true}
              />
              <MainInput
                isRequired={true}
                name='vatNumber'
                label={t('new-account.tva')}
                value={accountData.vatNumber}
                isDisabled={true}
              />
              <MainInput
                isRequired={true}
                name='campaignManager'
                label={t('new-account.boss-name')}
                value={accountData.campaignManager}
                onChange={(e) => setAccountData({ ...accountData, campaignManager: e.target.value })}
              />
              {accountError && accountError.campaignManager && (
                <p className='formError'>{accountError.campaignManager}</p>
              )}

              <MainInput
                isRequired={true}
                type={'tel'}
                inputProps={{ maxLength: 10 }}
                name='phoneNumber'
                label={t('new-account.phone')}
                value={accountData.phoneNumber}
                onChange={(e) => setAccountData({ ...accountData, phoneNumber: e.target.value })}
              />
              {accountError && accountError.phoneNumber && <p className='formError'>{accountError.phoneNumber}</p>}

              <MainInput
                isRequired={true}
                name='street'
                label={t('new-account.street')}
                value={accountData.address.street}
                onChange={(e) =>
                  setAccountData({ ...accountData, address: { ...accountData.address, street: e.target.value } })
                }
              />
              {accountError && accountError.address && accountError.address.street && (
                <p className='formError'>{accountError.address.street}</p>
              )}

              <div className='address'>
                <MainInput
                  width={'115px'}
                  inputProps={{ maxLength: 5 }}
                  type={'tel'}
                  isRequired={true}
                  name='zipcode'
                  label={t('new-account.zipcode')}
                  value={accountData.address.zipcode}
                  onChange={(e) =>
                    setAccountData({ ...accountData, address: { ...accountData.address, zipcode: e.target.value } })
                  }
                />
                <MainInput
                  width={'275px'}
                  isRequired={true}
                  name='city'
                  label={t('new-account.city')}
                  value={accountData.address.city}
                  onChange={(e) =>
                    setAccountData({ ...accountData, address: { ...accountData.address, city: e.target.value } })
                  }
                />
              </div>
              {accountError && accountError.address && accountError.address.zipcode && (
                <p className='formError'>{accountError.address.zipcode}</p>
              )}

              <MainInput
                isRequired={true}
                name='country'
                label={t('new-account.country')}
                value={accountData.address.country}
                onChange={(e) =>
                  setAccountData({ ...accountData, address: { ...accountData.address, country: e.target.value } })
                }
              />

              <MainButton label={t('save')} />

              {accountError && accountError.general && <p className='formError'>{accountError.general}</p>}
              {isUpdated && (
                <p className='formError' style={{ color: BrandColor }}>
                  {t('isUpdated')}
                </p>
              )}
            </form>
          </section>
        </>
      ) : userAccount.isLoading ? (
        <p>Chargement ...</p>
      ) : (
        <p> {t('error.account-data')}</p>
      )}
    </LayoutPrincipal>
  );
}
