import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

interface MainModalProps {
  children: any;
  isOpen: boolean;
  handleClose: () => void;
  width?: number;
  maxHeight?: number;
  minHeight?: number;
  isScroll?: boolean;
}

export default function MainModal(props: MainModalProps) {
  const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: props.width ? props.width : 400,
    minHeight: props.minHeight ? props.minHeight : 200,
    maxHeight: props.maxHeight ? props.maxHeight : 600,
    bgcolor: 'background.paper',
    border: '1px solid grey',
    borderRadius: 5,
    boxShadow: 24,
    p: 4,
    overflow: props.isScroll ? 'scroll' : 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <Modal
      open={props.isOpen}
      onClose={() => {
        props.handleClose();
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      // to avoid mui adding useless padding to body
      disableScrollLock={true}
    >
      <Box sx={style}>{props.children}</Box>
    </Modal>
  );
}
