import { createApi } from '@reduxjs/toolkit/query/react';
import { AdvertiserAccount, User, UserAccount } from '../models/user';
import { baseQueryWithReauth } from './BaseQuery';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['User'],
  endpoints: (builder) => ({
    getUserAccount: builder.query<UserAccount, void>({
      query: () => 'user_advertiser_account/',
      providesTags: ['User'],
    }),

    createAdvertiserUser: builder.mutation({
      query: (body: UserAccount) => ({
        url: 'user_advertiser_account/',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['User'],
    }),
    updateUserMail: builder.mutation({
      query: (user: User) => ({
        url: 'user_advertiser_account/',
        method: 'PATCH',
        body: { user: user },
      }),
      invalidatesTags: ['User'],
    }),
    updateAccountData: builder.mutation({
      query: (advertiserAccount: AdvertiserAccount) => ({
        url: 'user_advertiser_account/',
        method: 'PATCH',
        body: { advertiserAccount: advertiserAccount },
      }),
      invalidatesTags: ['User'],
    }),
  }),
});

export const {
  useCreateAdvertiserUserMutation,
  useGetUserAccountQuery,
  useUpdateUserMailMutation,
  useUpdateAccountDataMutation,
} = userApi;
export const { endpoints, reducerPath, reducer, middleware } = userApi;
