import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import trashcan from '../assets/icons/trash-can.svg';
import AddButton from '../component/AddButton';
import AdDisplay from '../component/AdDisplay';
import AdSummary from '../component/AdSummary';
import { DownloadFilesButton } from '../component/DownloadFilesButton';
import LayoutPrincipal from '../component/LayoutPrincipal';
import MainButton from '../component/MainButton';
import MainInput from '../component/MainInput';
import MainModal from '../component/MainModal';
import { CHAMP_LIMIT, TEAMPULSE_LIMITE } from '../constants/priceLimit.constant';
import { BrandColor, GreyColor, handlePartnerColor } from '../helper/colorHelper';
import { dateFormatDDMMAA, minEndDate_calculation } from '../helper/dateHelper';
import {
  deleteCampaignByNameToLocalStorage,
  registerCampaignToLocalStorgae,
} from '../helper/localStorage/localStorageCampaignHelper';
import { getPriceWithCoupon, getTTCPrice, getViewsWithBudget, roundNumber } from '../helper/priceHelper';
import { IAdRedux, IAdSubmitAPI, MediaType, PartnerEnum, PlacementTypeEnum } from '../models/ad';
import { ICampaignRedux, PaymentStatus } from '../models/campaign';
import { Coupon } from '../models/coupon';
import { useCreateCampaignMutation, useLazyGetCampaignVadsQuery } from '../services/CampaignApi';
import { useLazyGetCouponQuery } from '../services/CouponApi';
import { useGetCreditBalanceQuery } from '../services/CreditApi';
import {
  createNewAd,
  deleteAd,
  deleteCoupon,
  recoverCoupon,
  recoverEndDateTime,
  recoverStartDateTime,
  resetCampaign,
} from '../slice/CampaignSlice';
import { RootState } from '../store';
import modifyIcon from './../assets/icons/modify-item.svg';
import './CampaignDetails.scss';

function CampaignDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const currentCampaign: ICampaignRedux = useSelector((state: RootState) => state.campaign);

  const [showModalDates, setShowModalDates] = useState<boolean>(false);
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
  const [showModalRegister, setShowModalRegister] = useState<boolean>(false);
  const [errorPurchaseLimit, setErrorPurchaseLimit] = useState<string | undefined>();
  const [campaignFormDates, setCampaignFormDates] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: new Date(currentCampaign.startDatetime).toISOString().slice(0, 10),
    endDate: currentCampaign.endDatetime ? new Date(currentCampaign.endDatetime).toISOString().slice(0, 10) : '',
  });
  const adIdToDelete = useRef<number | null>(null);

  const codePromo = useRef<string>('');
  const [errorCodePromoApi, setErrorCodePromoApi] = useState<string>('');

  const [errorCreateCampaignApi, setErrorCreateCampaignApi] = useState<string | undefined>();
  const [errorCreditBalanceUsed, setErrorCreditBalanceUsed] = useState<string | undefined>();

  const [createCampaign] = useCreateCampaignMutation();

  const [getCoupon] = useLazyGetCouponQuery();
  const [getVads] = useLazyGetCampaignVadsQuery();
  const availableCredit = useGetCreditBalanceQuery();

  const adsPriceByPartner: { [key in PartnerEnum]?: number } = useMemo(() => {
    const priceByPartner: { [key in PartnerEnum]?: number } = {};
    currentCampaign.ads &&
      currentCampaign.ads.length > 0 &&
      currentCampaign.ads.forEach((ad: IAdRedux) => {
        if (ad.partner) {
          if (priceByPartner[ad.partner]) {
            priceByPartner[ad.partner]! += ad.billPriceExcl;
          } else {
            priceByPartner[ad.partner] = ad.billPriceExcl;
          }
        }
      });
    return priceByPartner;
  }, [currentCampaign.ads]);

  const campaignPrice = Object.values(adsPriceByPartner).reduce(
    (total: number, partnerAdsPrice: number) => total + partnerAdsPrice,
    0,
  );

  const adsPriceWithCouponsByPartner: { [key in PartnerEnum]?: number } = { ...adsPriceByPartner };
  currentCampaign.coupons.forEach((coupon: Coupon) => {
    const partner = coupon.distributionPartner;
    if (adsPriceWithCouponsByPartner[partner] !== undefined) {
      adsPriceWithCouponsByPartner[partner] = getPriceWithCoupon(adsPriceWithCouponsByPartner[partner]!, coupon);
    }
  });

  const campaignPriceWithCoupons = roundNumber(
    Object.values(adsPriceWithCouponsByPartner).reduce(
      (total: number, partnerAdsPriceWithCoupon: number) => total + partnerAdsPriceWithCoupon,
      0,
    ),
  );

  const creditBalanceUsed = useMemo(() => {
    if (currentCampaign.isCampaignFromAPI) return currentCampaign.creditBalanceUsed;
    if (!availableCredit || !availableCredit.data) return 0;
    const creditBalance = availableCredit.data.creditBalance;
    if (campaignPriceWithCoupons > creditBalance) {
      return availableCredit.data.creditBalance;
    }
    if (campaignPriceWithCoupons <= creditBalance) {
      return campaignPriceWithCoupons;
    }
    return 0;
  }, [availableCredit, campaignPriceWithCoupons, currentCampaign.creditBalanceUsed, currentCampaign.isCampaignFromAPI]);

  const isCreditBalanceUsedOK = useMemo(() => {
    if (currentCampaign.paymentStatus === PaymentStatus.PAID) return true;
    if (!availableCredit || !availableCredit.data) return false;
    if (creditBalanceUsed == campaignPriceWithCoupons || creditBalanceUsed == availableCredit.data.creditBalance) {
      setErrorCreditBalanceUsed(undefined);
      return true;
    } else {
      setErrorCreditBalanceUsed(t('error.error-credit-balance-used-mismatch-balance'));
      return false;
    }
  }, [availableCredit, campaignPriceWithCoupons, creditBalanceUsed, t]);

  const campaignPriceWithCouponsAndCredit = roundNumber(campaignPriceWithCoupons - creditBalanceUsed);

  useEffect(() => {
    if (currentCampaign.paymentStatus === PaymentStatus.PAID) return;
    const limit =
      (adsPriceByPartner[PartnerEnum.CP] ? CHAMP_LIMIT : 0) +
      (adsPriceByPartner[PartnerEnum.TP] ? TEAMPULSE_LIMITE : 0);
    if (campaignPriceWithCouponsAndCredit > limit) {
      setErrorPurchaseLimit(t('error.campaign-limit-purchase', { limit: limit }));
    } else {
      setErrorPurchaseLimit(undefined);
    }
  }, [campaignPriceWithCouponsAndCredit, adsPriceByPartner, t, currentCampaign]);

  const today = new Date();
  // today + 3 days, formatted in ISO for the input date options.
  // toISOString convert the date to UTC which can change the day, so we use Date.UTC to force to have the good day.
  const minCampaignInputDate = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate() + 3))
    .toISOString()
    .slice(0, 10);

  function goBackHomePage() {
    dispatch(resetCampaign());
    navigate('/campaigns');
  }

  function getCouponByCode() {
    getCoupon(codePromo.current)
      .unwrap()
      .then((couponData) => {
        setErrorCodePromoApi('');
        // If there are no ads with partner corresponding to the coupon partner, we do not add the coupon
        const partnerAds = currentCampaign.ads.filter((ad: IAdRedux) => ad.partner === couponData.distributionPartner);
        if (partnerAds.length === 0) {
          setErrorCodePromoApi(t('error.discount-partner'));
          return;
        }
        // If there is already a coupon for this partner we do not add the coupon
        const partnerCoupons = currentCampaign.coupons.filter(
          (c: Coupon) => c.distributionPartner === couponData.distributionPartner,
        );
        if (partnerCoupons.length > 0) {
          setErrorCodePromoApi(t('error.discount-code-too-many-for-partner'));
          return;
        }

        dispatch(
          recoverCoupon({
            id: couponData.id,
            type: couponData.type,
            distributionPartner: couponData.distributionPartner,
            ...(couponData.discountAmount ? { discountAmount: couponData.discountAmount } : {}),
            ...(couponData.numberOfFreeUnits ? { numberOfFreeUnits: couponData.numberOfFreeUnits } : {}),
            ...(couponData.message ? { message: couponData.message } : {}),
          }),
        );
      })
      .catch((error) => {
        if (error && error.data && error.data.errorCode === 'COUPON_ALREADY_USED') {
          setErrorCodePromoApi(t('error.discount-code-already-used'));
        } else if (error && error.data && error.data.errorCode === 'COUPON_DATES_INVALID') {
          setErrorCodePromoApi(t('error.discount-code-date-invalid'));
        } else {
          setErrorCodePromoApi(t('error.discount-code-unknown'));
        }
      });
  }

  const handleNewAd = (partner: PartnerEnum) => {
    dispatch(createNewAd(partner));
    navigate('/new-ad');
  };

  async function handleSubmitCampaign(campaign: ICampaignRedux) {
    setErrorCreateCampaignApi('');

    const body = {
      form: {
        name: campaign.name,
        ...(campaign.idApi
          ? {
              id: campaign.idApi,
            }
          : {}),
        startDatetime: campaign.startDatetime,
        endDatetime: campaign.endDatetime,
        coupons: currentCampaign.coupons.map((coupon: Coupon) => coupon.id),
        creditBalanceUsed: creditBalanceUsed,
        ads: campaign.ads.map((ad: IAdRedux) => {
          const sports =
            ad.public.sports &&
            ad.public.sports.map((sport) => (ad.partner === PartnerEnum.TP ? sport.name : (sport.id as string)));
          const ageString = ad.public.age && ad.public.age.map((age) => age.name).join();

          const adObjectAPI: IAdSubmitAPI = {
            name: campaign.name,
            placementType: ad.placementType,
            ...(ad.idApi
              ? {
                  id: ad.idApi,
                }
              : {}),
            billPriceExcl: ad.billPriceExcl,
            destUrl: ad.dest_url,
            createdWithAmbassador: ad.created_with_ambassador,
            uploadedMedia: ad.media_id,
            partner: ad.partner,
            public: {
              gender: ad.public.sexe,
              ...(sports ? { sports: sports } : {}),
              ...(ad.public.clubs ? { clubs: ad.public.clubs } : {}),
              ...(ad.public.matchs ? { matches: ad.public.matchs } : {}),
              ...(ad.public.zipcodes ? { zipcodes: ad.public.zipcodes } : {}),
              ...(ad.public.departments ? { departments: ad.public.departments } : {}),
              ...(ageString ? { age: ageString } : {}),
            },
          };
          return adObjectAPI;
        }),
      },
    };
    const { error, data }: any = await createCampaign(body);
    if (data) {
      deleteCampaignByNameToLocalStorage(campaign.name);
      return data;
    } else {
      switch (error?.data?.errorCode) {
        case 'COUPON_ALREADY_USED':
          setErrorCreateCampaignApi(t('error.discount-code-already-used'));
          break;
        case 'COUPON_NOT_AVAILABLE':
          setErrorCreateCampaignApi(t('error.discount-code-not-available'));
          break;
        case 'COUPON_DATES_INVALID':
          setErrorCreateCampaignApi(t('error.discount-code-date-invalid'));
          break;
        case 'NEGATIVE_CREDIT_BALANCE_USED':
          setErrorCreateCampaignApi(t('credit-errors.negative'));
          break;
        case 'NOT_ENOUGH_CREDIT_BALANCE':
          setErrorCreateCampaignApi(t('credit-errors.not-enough'));
          break;
        case 'TOO_MANY_COUPON_FOR_PARTNER':
          setErrorCreateCampaignApi(t('error.discount-code-too-many-for-partner'));
          break;
        case 'CREDIT_BALANCE_USED_MISMATCH_BALANCE':
          setErrorCreateCampaignApi(t('error.error-credit-balance-used-mismatch-balance'));
          break;
        default:
          setErrorCreateCampaignApi(t('error.general'));
      }
    }
  }

  async function submitCampaign(campaign: ICampaignRedux) {
    // in case user applied credit, didn't validate campaign and return later with less availableCredit
    const campaignData = await handleSubmitCampaign(campaign);
    if (campaignData && campaignData.vads) {
      return navigate('/payment', {
        state: {
          vads: campaignData.vads,
        },
      });
    }
  }

  // add error handling in case credit balance not enough
  const handlePendingPaiement = (id: string) => {
    getVads(id)
      .unwrap()
      .then((resp) => {
        navigate('/payment', {
          state: {
            vads: resp.vads,
          },
        });
      })
      .catch((error) => {
        if (error.data.errorCode === 'NOT_ENOUGH_CREDIT_BALANCE') {
          setErrorCreateCampaignApi(t('credit-errors.not-enough'));
        } else setErrorCreateCampaignApi(t('error.general'));
      });
  };

  const handlePaiementButton = () => {
    // We focus on date and ignore time
    const minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 3);
    const campaignStartDateTime = new Date(currentCampaign.startDatetime);
    const campaignStartDate = new Date(
      campaignStartDateTime.getFullYear(),
      campaignStartDateTime.getMonth(),
      campaignStartDateTime.getDate(),
    );

    if (currentCampaign.isCampaignFromAPI && currentCampaign.paymentStatus === PaymentStatus.PAID) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
          }}
        >
          <div style={{ marginRight: 5 }}>
            <DownloadFilesButton
              campaignId={currentCampaign.idApi}
              invoice={!!currentCampaign.invoice}
              orderForm={!!currentCampaign.orderForm}
              creditNote={!!currentCampaign.creditNote}
              deliveryNote={!!currentCampaign.deliveryNote}
            />
          </div>
          <MainButton label={t('paiement-status.approved')} disabled={true} backgroundColor={GreyColor} />
        </div>
      );
    } else if (campaignStartDate && campaignStartDate < minDate) {
      return (
        <div>
          <MainButton label={t('confirm-pay')} disabled={true} backgroundColor={GreyColor} />
          <p className='formError'>{t('error.campaignDate')}</p>
        </div>
      );
    } else if (currentCampaign.isCampaignFromAPI && currentCampaign.paymentStatus !== PaymentStatus.PAID) {
      return (
        <div>
          <MainButton
            label={t('confirm-pay')}
            disabled={!isCreditBalanceUsedOK}
            backgroundColor={isCreditBalanceUsedOK ? BrandColor : GreyColor}
            onClickButton={() => {
              currentCampaign.idApi && handlePendingPaiement(currentCampaign.idApi);
            }}
          />
          {campaignPriceWithCouponsAndCredit === 0 && <p className='formInfo'>{t('info.paymentAmountZero')}</p>}
        </div>
      );
    } else {
      return (
        <div>
          <MainButton
            label={t('confirm-pay')}
            onClickButton={() => {
              submitCampaign(currentCampaign);
            }}
            disabled={
              !(
                currentCampaign &&
                currentCampaign.ads &&
                currentCampaign.ads.length > 0 &&
                errorPurchaseLimit == undefined &&
                isCreditBalanceUsedOK
              )
            }
            backgroundColor={
              currentCampaign &&
              currentCampaign.ads &&
              currentCampaign.ads.length > 0 &&
              errorPurchaseLimit == undefined &&
              isCreditBalanceUsedOK
                ? BrandColor
                : GreyColor
            }
          />
          {campaignPriceWithCouponsAndCredit === 0 && <p className='formInfo'>{t('info.paymentAmountZero')}</p>}
        </div>
      );
    }
  };

  return (
    <LayoutPrincipal
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
      }}
      isShowingVideoTuto={true}
      isShowingAmbassadorHelp={currentCampaign.isCampaignFromAPI ? false : true}
      titleStep={t('title.campaign-details') + currentCampaign.name}
    >
      <MainModal
        isOpen={showModalDelete}
        handleClose={() => {
          setShowModalDelete(false);
        }}
      >
        <p className='titleStep'>{t('delete-ad')}</p>
        <MainButton
          label={t('validation')}
          onClickButton={() => {
            adIdToDelete.current && dispatch(deleteAd(adIdToDelete.current));
            setShowModalDelete(false);
          }}
        />
        <MainButton label={t('cancel')} backgroundColor={'grey'} onClickButton={() => setShowModalDelete(false)} />
      </MainModal>

      <MainModal
        isOpen={showModalRegister}
        handleClose={() => {
          setShowModalRegister(false);
        }}
      >
        <p className='titleStep'>{t('save-campaign')}</p>
        <MainButton
          label={t('save')}
          onClickButton={() => {
            registerCampaignToLocalStorgae(currentCampaign);
            dispatch(resetCampaign());
            navigate('/campaigns');
          }}
        />
        <MainButton
          label={t('homepage')}
          backgroundColor={'grey'}
          onClickButton={() => {
            navigate('/campaigns');
            dispatch(resetCampaign());
          }}
        />
      </MainModal>

      <MainModal
        isOpen={showModalDates}
        handleClose={() => {
          // reset to campaign value
          setCampaignFormDates({
            startDate: new Date(currentCampaign.startDatetime).toISOString().slice(0, 10),
            endDate: currentCampaign.endDatetime
              ? new Date(currentCampaign.endDatetime).toISOString().slice(0, 10)
              : '',
          });
          setShowModalDates(false);
        }}
      >
        <div className='dateModalContent'>
          <div>
            <p style={{ marginBottom: 5 }}>{t('start-date-time')} *</p>
            <MainInput
              type={'date'}
              width={'180px'}
              inputProps={{ min: minCampaignInputDate }}
              value={campaignFormDates.startDate}
              onChange={(e) => {
                setCampaignFormDates((prevCampaignFormDates) => {
                  const newStartDate = e.target.value;
                  return {
                    startDate: newStartDate,
                    endDate:
                      prevCampaignFormDates.endDate &&
                      prevCampaignFormDates.endDate <
                        minEndDate_calculation(newStartDate, campaignFormDates.startDate, minCampaignInputDate)
                        ? minEndDate_calculation(newStartDate, campaignFormDates.startDate, minCampaignInputDate)
                        : prevCampaignFormDates.endDate,
                  };
                });
              }}
            />
          </div>
          <div>
            <p style={{ marginBottom: 5 }}>{t('end-date-time')}</p>
            <MainInput
              type={'date'}
              width={'180px'}
              inputProps={{ min: minEndDate_calculation(undefined, campaignFormDates.startDate, minCampaignInputDate) }}
              value={campaignFormDates.endDate}
              onChange={(e) => {
                setCampaignFormDates((prevCampaignFormDates) => ({
                  ...prevCampaignFormDates,
                  endDate: e.target.value,
                }));
              }}
            />
          </div>
          <MainButton
            label={t('validation')}
            disabled={!campaignFormDates.startDate}
            backgroundColor={!campaignFormDates.startDate ? GreyColor : BrandColor}
            onClickButton={() => {
              dispatch(recoverStartDateTime(campaignFormDates.startDate));
              campaignFormDates.endDate !== ''
                ? dispatch(recoverEndDateTime(campaignFormDates.endDate))
                : dispatch(recoverEndDateTime(undefined));
              setShowModalDates(false);
            }}
          />
        </div>
      </MainModal>

      <section className='annonceContainer'>
        {currentCampaign &&
          currentCampaign.ads &&
          currentCampaign.ads.length > 0 &&
          currentCampaign.ads.map((ad: IAdRedux) => {
            return (
              <AdDisplay
                key={ad.id}
                brandColor={handlePartnerColor(ad.partner)}
                image={ad.media ? ad.media : ''}
                placementType={ad.placementType}
                isVideo={ad.media_type === MediaType.VIDEO}
                setShowModalDelete={setShowModalDelete}
                adId={ad.id}
                recoverAdIdToDelete={(id: number) => (adIdToDelete.current = id)}
                allowChanges={currentCampaign.isCampaignFromAPI ? false : true}
                paidImpresssions={
                  ad.fixedQuantity
                    ? ad.fixedQuantity
                    : getViewsWithBudget(ad.placementType as PlacementTypeEnum, ad.billPriceExcl)
                }
                impressions={currentCampaign.isCampaignFromAPI && ad.impressions ? ad.impressions : undefined}
                partner={ad.partner}
              />
            );
          })}
        {!currentCampaign.isCampaignFromAPI && (
          <article
            className={
              currentCampaign && currentCampaign.ads && currentCampaign.ads.length > 0
                ? 'newAdButtons'
                : 'emptyAdButtons'
            }
          >
            <AddButton
              label={t('new-ad')}
              partner={PartnerEnum.CP}
              onClickAddButton={() => {
                handleNewAd(PartnerEnum.CP);
              }}
            />
            <AddButton
              label={t('new-ad')}
              partner={PartnerEnum.TP}
              onClickAddButton={() => {
                handleNewAd(PartnerEnum.TP);
              }}
            />
          </article>
        )}
      </section>

      <MainButton
        label={t('homepage')}
        height={60}
        width={350}
        style={{ marginBottom: '40px' }}
        onClickButton={() => {
          currentCampaign.isCampaignFromAPI ? goBackHomePage() : setShowModalRegister(true);
        }}
      />

      {currentCampaign && currentCampaign.ads && currentCampaign.ads.length > 0 && (
        <>
          <h2 className='recapTitle'>{t('title.summary')}</h2>
          <p style={{ display: 'flex', alignItems: 'center' }}>
            <span>
              {t('start-campaign')}
              {dateFormatDDMMAA(new Date(currentCampaign.startDatetime))}
              {currentCampaign.endDatetime &&
                ' ' + t('end-campaign') + dateFormatDDMMAA(new Date(currentCampaign.endDatetime))}
            </span>
            {!currentCampaign.isCampaignFromAPI && (
              <img
                src={modifyIcon}
                alt='modify campaign dates'
                onClick={() => setShowModalDates(true)}
                className='modifyIcon'
              />
            )}
          </p>
          <section className='recapContainer'>
            {currentCampaign.ads.map((ad: IAdRedux) => {
              return (
                <AdSummary
                  key={ad.id}
                  brandColor={handlePartnerColor(ad.partner)}
                  type={ad.placementType}
                  views={
                    ad.fixedQuantity
                      ? ad.fixedQuantity
                      : getViewsWithBudget(ad.placementType as PlacementTypeEnum, ad.billPriceExcl)
                  }
                  price={ad.billPriceExcl}
                  options={ad.public.time_match}
                  partner={ad.partner}
                />
              );
            })}
            <br />

            {currentCampaign.coupons.map((coupon: Coupon) => {
              return (
                <div className='couponSummary' key={coupon.id}>
                  <AdSummary
                    brandColor={handlePartnerColor(coupon.distributionPartner)}
                    coupon={coupon}
                    partner={coupon.distributionPartner}
                    price={
                      adsPriceByPartner[coupon.distributionPartner] !== undefined &&
                      adsPriceWithCouponsByPartner[coupon.distributionPartner] !== undefined
                        ? adsPriceByPartner[coupon.distributionPartner]! -
                          adsPriceWithCouponsByPartner[coupon.distributionPartner]!
                        : 0
                    }
                  />
                  {!currentCampaign.isCampaignFromAPI && (
                    <button
                      className='deleteCoupon'
                      style={{ backgroundColor: handlePartnerColor(coupon.distributionPartner) }}
                      onClick={() => dispatch(deleteCoupon(coupon.id))}
                    >
                      <img src={trashcan} alt='delete coupon' />
                    </button>
                  )}
                </div>
              );
            })}

            <div className='code-priceContainer'>
              <section>
                {!currentCampaign.isCampaignFromAPI && (
                  <article>
                    <p className='code'>{t('discount-code')}</p>
                    <div style={{ display: 'flex' }}>
                      <div>
                        <MainInput
                          isRequired={false}
                          type={'text'}
                          name={'code promotion'}
                          label={t('code')}
                          width={'300px'}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setErrorCodePromoApi('');
                            codePromo.current = event.target.value;
                          }}
                        />
                        {errorCodePromoApi && (
                          <p className='formError' style={{ width: '300px' }}>
                            {errorCodePromoApi}
                          </p>
                        )}
                      </div>
                      <div style={{ marginTop: '-13px', marginLeft: '15px' }}>
                        <MainButton
                          label={t('apply')}
                          width={100}
                          height={38}
                          onClickButton={() => {
                            getCouponByCode();
                          }}
                          // To be redefined later to limit the number of coupons per partner
                          // disabled={currentCampaign.coupons.length > 0 ? true : false}
                          // backgroundColor={currentCampaign.coupon ? 'grey' : BrandColor}
                        />
                      </div>
                    </div>
                  </article>
                )}
              </section>

              <article className='price'>
                <div className='price-wrapper'>
                  <p style={{ fontSize: '20px', color: BrandColor, marginBottom: '2px' }}>
                    {t('campaigns.total-no-taxes')}
                  </p>
                  <p style={{ fontSize: '20px' }}>{campaignPriceWithCoupons} €</p>
                </div>
                {!!creditBalanceUsed && (
                  <>
                    <div className='price-wrapper'>
                      <p style={{ fontSize: '20px', color: BrandColor, marginBottom: '2px' }}>
                        {t('campaigns.total-credit-applied')}
                      </p>
                      <p style={{ fontSize: '20px' }}>-{creditBalanceUsed} €</p>
                    </div>
                    <div className='price-wrapper'>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <p style={{ fontSize: '20px', color: BrandColor, marginBottom: '2px' }}>
                          {t('campaigns.total-without-taxes')}
                        </p>
                      </div>
                      <p style={{ fontSize: '20px' }}>{campaignPriceWithCouponsAndCredit} €</p>
                    </div>
                  </>
                )}
                <div className='price-wrapper'>
                  <p style={{ fontSize: '20px', color: BrandColor, marginBottom: '2px' }}>{t('campaigns.with-vat')}</p>
                  <p style={{ fontSize: '20px' }}>
                    {roundNumber(getTTCPrice(campaignPriceWithCouponsAndCredit) - campaignPriceWithCouponsAndCredit)} €
                  </p>
                </div>
                <div className='price-wrapper'>
                  <p style={{ fontSize: '20px', color: BrandColor }}>{t('campaigns.total-price')}</p>
                  <p style={{ fontSize: '20px' }}>{getTTCPrice(campaignPriceWithCouponsAndCredit)} €</p>
                </div>
                <div style={{ textAlign: 'right', display: 'flex', flexDirection: 'column' }}>
                  {handlePaiementButton()}
                  {(errorCreateCampaignApi || errorPurchaseLimit || errorCreditBalanceUsed) && (
                    <p className='formError' style={{ paddingTop: '10px' }}>
                      {errorCreditBalanceUsed || errorCreateCampaignApi || errorPurchaseLimit}
                    </p>
                  )}
                </div>
              </article>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
                gap: 2,
                paddingTop: 20,
                color: 'grey',
                fontWeight: 'normal',
                fontSize: 12,
              }}
            >
              <p>{t('canpaign-greater-than-limit')}</p>
              <a
                href='mailto:regiepub@champs.fr'
                style={{
                  color: 'grey',
                }}
                rel='noreferrer'
                target='_blank'
              >
                regiepub@champs.fr
              </a>
            </div>
          </section>
        </>
      )}
    </LayoutPrincipal>
  );
}

export default CampaignDetails;
