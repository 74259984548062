import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { BrandColor } from '../helper/colorHelper';

interface MainCheckBoxProps {
  label?: string;
  color?: string;
  size?: number;
  initialValue?: boolean;
  onSelectItem?: (arg: boolean) => void;
  justifyContent?: string;
  disabled?: boolean;
  disabledIcon?: JSX.Element;
}

export default function MainCheckBox(props: MainCheckBoxProps) {
  const [isSelected, setIsSelected] = useState<boolean>(props.initialValue ? props.initialValue : false);
  function changeItemValue() {
    setIsSelected(!isSelected);
    props.onSelectItem && props.onSelectItem(!isSelected);
  }
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: props.justifyContent || 'flex-start',
        alignItems: 'center',
      }}
    >
      <Checkbox
        disabled={!!props.disabled}
        // use a double negation operator to convert the undefined to false to avoid an mui error if props.initialValue is undefined
        checked={!!props.initialValue}
        icon={!!props.disabled && !!props.disabledIcon ? props.disabledIcon : <CheckBoxOutlineBlankIcon />}
        sx={{
          paddingLeft: 0,
          color: props.color ? props.color : BrandColor,
          '&.Mui-checked': {
            color: props.color ? props.color : BrandColor,
          },
          '& .MuiSvgIcon-root': {
            fontSize: props.size ? props.size : 30,
          },
        }}
        value={isSelected}
        onClick={() => changeItemValue()}
      />
      {props.label && (
        <p
          style={{
            minWidth: '80px',
            color: props.disabled ? 'grey' : props.color ? props.color : BrandColor,
            fontFamily: 'RobotoBold',
            fontStyle: props.disabled ? 'italic' : 'normal',
          }}
        >
          {props.label}
        </p>
      )}
    </div>
  );
}
