import React, { useState } from 'react';
import { GreyColor } from '../helper/colorHelper';
import './UploadAd.scss';
import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';

interface UploadAdProps {
  style?: object;
  recoverFile: (arg: any) => void;
  initialValue?: string;
  isVideo?: boolean;
  disabled?: boolean;
  partnerColor: string;
}

const UploadMedia = ({ style, recoverFile, initialValue, isVideo, disabled, partnerColor }: UploadAdProps) => {
  const { t } = useTranslation();

  const brandColor = partnerColor;
  const [selectedMedia, setSelectedMedia] = useState<File | null>(null);

  const [errorUpload, setErrorUpload] = useState<boolean>(false);

  // to change the color of the file input button, plain old js!
  const inputButton = document.querySelector('.inputEnabled') as HTMLElement;
  const handleButtonColor = () => {
    if (inputButton && disabled) {
      inputButton.style.setProperty('--button-color', GreyColor);
    } else if (inputButton) {
      inputButton.style.setProperty('--button-color', brandColor);
    }
  };

  handleButtonColor();

  return (
    <div style={style}>
      <h3 style={{ color: brandColor }}>{isVideo ? t('advert.upload-video') : t('advert.upload-image')}</h3>
      {(selectedMedia || initialValue) && !errorUpload ? (
        <>
          <div className='containerUploadAd'>
            {!isVideo ? (
              <img
                alt='your ad overview'
                style={{ maxWidth: '400px', maxHeight: '200px' }}
                src={selectedMedia ? URL.createObjectURL(selectedMedia) : initialValue}
              />
            ) : (
              <ReactPlayer
                controls
                url={selectedMedia ? URL.createObjectURL(selectedMedia) : initialValue}
                width={'400px'}
                height={'250px'}
              />
            )}
            <br />
          </div>
        </>
      ) : errorUpload ? (
        <div className='containerUploadAd'>
          <p style={{ width: '400px', textAlign: 'center', color: 'red', fontWeight: 'bold' }}>
            {t('error.media-format')}
          </p>
        </div>
      ) : (
        <div className='containerUploadAd' />
      )}

      <input
        id='file-upload'
        className='inputEnabled'
        type='file'
        accept={isVideo ? 'video/mp4' : 'image/png, image/jpeg, image/jpg, image/gif'}
        name='adMedia'
        disabled={disabled}
        style={{ color: brandColor }}
        onChange={(event: any) => {
          if (
            !isVideo &&
            (event.target.files[0].type === 'image/jpeg' ||
              event.target.files[0].type === 'image/jpg' ||
              event.target.files[0].type === 'image/png' ||
              event.target.files[0].type === 'image/gif')
          ) {
            setSelectedMedia(event.target.files[0]);
            setErrorUpload(false);
            recoverFile(event.target.files[0]);
          } else if (isVideo && event.target.files[0].type === 'video/mp4') {
            setSelectedMedia(event.target.files[0]);
            recoverFile(event.target.files[0]);
            setErrorUpload(false);
          } else {
            setErrorUpload(true);
            setSelectedMedia(null);
          }
        }}
      />
    </div>
  );
};

export default UploadMedia;
