import React from 'react';
import { useTranslation } from 'react-i18next';
import { PartnerEnum, PlacementTypeEnum } from '../models/ad';
import { Coupon, CouponType } from '../models/coupon';
import './AdSummary.scss';

interface AdSummaryProps {
  type?: PlacementTypeEnum;
  views?: number;
  coupon?: Coupon;
  credit?: boolean;
  price?: number;
  options?: string[];
  already_print?: number;
  brandColor?: string;
  partner?: PartnerEnum;
}

function AdSummary(props: AdSummaryProps) {
  const { t } = useTranslation();
  const couponUnit =
    props.coupon?.type === CouponType.PERCENTAGE ? '%' : props.coupon?.type === CouponType.FIXED ? '€' : null;
  const partner = props.partner === PartnerEnum.TP ? 'TeamPulse' : "CHAMP'S";

  return (
    <>
      {props.coupon ? (
        <article style={{ width: '100%' }}>
          <p>
            <em>
              {t(props.coupon.type)} {t('on')} {partner} :
            </em>
          </p>
          <ul className='recapLine'>
            {props.coupon.message ? (
              <li>
                <em>{props.coupon.message}</em>
              </li>
            ) : (
              <li>
                <em>{t('discount')}</em>
              </li>
            )}
            <div className='dots' style={{ borderBottom: `4px dotted grey` }}></div>
            <div className='couponPrice' style={{ color: props.brandColor }}>
              - {props.coupon.type === CouponType.FIXED && props.coupon.discountAmount}
              {couponUnit}
            </div>
          </ul>
        </article>
      ) : props.credit ? (
        <article style={{ width: '100%' }}>
          <em>{t('credit.credit')}</em>
          <ul className='recapLine'>
            <li>
              <em>{t('credit.summary')}</em>
            </li>
            <div className='dots' style={{ borderBottom: `4px dotted grey` }}></div>
            <div className='couponPrice' style={{ color: 'grey' }}>
              - {props.price} €
            </div>
          </ul>
        </article>
      ) : (
        <article style={{ width: '100%' }}>
          <p style={{ color: props.brandColor }}>
            {t('ad')} {props.type && `${t(props.type)} ${t('on')} ${partner}`}
          </p>

          <ul className='recapLine'>
            <li style={{ color: props.brandColor }}>
              {props.views && props.views + ' ' + t('views')}
              {/* {props.options && props.options.length > 0
              ? props.options.map((option: string) => {
                  return ', ' + t(option);
                })
              : ', ' + t('no-option')} */}
            </li>
            <div className='dots' style={{ borderBottom: `4px dotted ${props.brandColor}` }}></div>
            <div style={{ color: props.brandColor }}>{props.price} € HT</div>
          </ul>
        </article>
      )}
    </>
  );
}

export default AdSummary;
