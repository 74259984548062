import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './SignInAndSignUp.scss';
import { BrandColor } from '../helper/colorHelper';
import MainButton from '../component/MainButton';
import MainInput from '../component/MainInput';
import MainModal from '../component/MainModal';
import ChampsLogoHeader from './../assets/icons/logo-regie.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCheckResetPasswordMutation, useResetPasswordMutation } from '../services/ResetPasswordApi';

export default function ResetPassword() {
  const { token, email } = useParams<{ token: string; email: string }>();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [checkPassword] = useCheckResetPasswordMutation();
  const [submitPassword] = useResetPasswordMutation();

  const [newPassword, setNewPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();
  const [errorPassword, setErrorPassword] = useState<string>();
  const [showModalSuccess, setShowModalSucess] = useState<boolean>(false);
  const [isResetValid, setIsResetValid] = useState<boolean>(false);

  const verifyUser = async () => {
    if (email && token) {
      const { error }: any = await checkPassword({
        email: email,
        token: token,
      });
      if (error) {
        setErrorPassword(t('error.general'));
        console.log(error);
      } else {
        setIsResetValid(true);
      }
    }
  };

  useEffect(() => {
    verifyUser();
  }, []);

  const handleSubmitPassword = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (newPassword && newPassword.length < 8) {
      setErrorPassword(t('error.password-length'));
    } else if (newPassword !== confirmPassword) {
      setErrorPassword('Les 2 mots de passe ne sont pas identiques');
    } else if (newPassword && newPassword.length > 7 && newPassword === confirmPassword && email && token) {
      const { error }: any = await submitPassword({
        email: email,
        token: token,
        password1: newPassword,
        password2: confirmPassword,
      });
      if (error) {
        error.data.error
          ? setErrorPassword(t('error.general') + ': ' + error.data.error)
          : setErrorPassword(t('error.general'));
      } else {
        setShowModalSucess(true);
      }
    }
  };

  return (
    <main className='homeContainer'>
      <MainModal
        isOpen={showModalSuccess}
        handleClose={() => {
          setShowModalSucess(false);
        }}
      >
        <p style={{ margin: 0 }}>Votre nouveau mot de passe a bien été enregistré.</p>
        <MainButton
          label={'OK'}
          onClickButton={() => {
            setShowModalSucess(false);
            navigate('/');
          }}
        />
      </MainModal>
      <section className='login'>
        <img src={ChampsLogoHeader} alt="logo régie publicitaire CHAMP'S" className='logoRegie' width='260px' />
      </section>

      {isResetValid ? (
        <section className='newAccount'>
          <p>Entrez votre nouveau mot de passe</p>
          <form style={{ textAlign: 'center' }} onSubmit={handleSubmitPassword}>
            <MainInput
              isRequired={true}
              name='password'
              label={t('password')}
              onChange={(e) => setNewPassword(e.target.value)}
              value={newPassword}
              type='password'
            />

            <MainInput
              isRequired={true}
              name='confirmPassword'
              label='Confirmez votre mot de passe'
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
              type='password'
            />
            <p className='passwordSpec'>Au moins 8 caractères</p>
            {errorPassword && <p className='formError'>{errorPassword}</p>}
            <MainButton label={'Valider'} labelColor={BrandColor} backgroundColor={'white'} />
          </form>
        </section>
      ) : (
        <section className='newAccount'>
          <p>Vérification en cours</p>
          {errorPassword && <p className='formError'>{errorPassword}</p>}
        </section>
      )}
    </main>
  );
}
