import { PartnerEnum } from '../models/ad';

export const BrandColor = '#64DCBE';
export const TeampulseColor = '#FF730F';
export const WhiteColor = 'white';
export const BlueButton = '#acce9';
export const GreyColor = 'grey';
export const GreyLightColor = '#4F4F4F';
export const GreyClear = '#DEDCDC';
export const RedColor = 'red';

export const handlePartnerColor = (partner: PartnerEnum | undefined) => {
  if (partner === PartnerEnum.CP) {
    return BrandColor;
  } else if (partner === PartnerEnum.TP) {
    return TeampulseColor;
  } else return 'black';
};
