// import React, { useMemo } from 'react';
// import MenuItem from '@mui/material/MenuItem';
// import Select from '@mui/material/Select';
import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { dateFormatDDMMAA } from '../helper/dateHelper';
import { Autocomplete, Chip, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export enum ValueKeyEnum {
  NAME = 'name',
  ID = 'id',
}

export interface dataType {
  name: string;
  id: number | string;
  beginDatetime?: string;
  sport?: string;
  zipcode?: string;
}

interface PropsSelectedList {
  label?: string;
  data: dataType[];
  valueKey: ValueKeyEnum;
  value?: string[];
  handleValueChange?: (event: any, value: dataType[] | dataType | null) => void;
  color?: string;
  multiple?: boolean;
}

export default function MainSelectList(props: PropsSelectedList) {
  // // Use it to avoid to do find() all the time in renderValue()
  // const dataByValueKey = useMemo(() => {
  //   const result: { [key: string]: dataType } = {};
  //   props.data.forEach((d) => (result[d[props.valueKey].toString()] = d));
  //   return result;
  // }, [props.data, props.valueKey]);
  const { t } = useTranslation();

  return (
    <div>
      <FormControl style={{ width: '450px', marginBottom: '15px' }}>
        <InputLabel>{props.label && props.label}</InputLabel>
        <Autocomplete
          multiple={props.multiple}
          id='tags-standard'
          options={props.data}
          onChange={props.handleValueChange}
          value={
            props.value
              ? props.data.filter((d) =>
                  props.value?.some((v) => (props.valueKey === ValueKeyEnum.ID ? v === d.id : v === d.name)),
                )
              : []
          }
          // getOptionLabel allows the search to get the labels on which it must filter.
          getOptionLabel={(option) => {
            let label = '';
            label += option.beginDatetime ? `[${dateFormatDDMMAA(new Date(option.beginDatetime))}] ` : '';
            label += option.zipcode ? `[${option.zipcode.substring(0, 2)}] ` : '';
            label += option.sport ? `[${option.sport}] ` : '';
            label += option.name;
            return label;
          }}
          renderOption={(props, option) => {
            let label = '';
            label += option.beginDatetime ? `[${dateFormatDDMMAA(new Date(option.beginDatetime))}] ` : '';
            label += option.zipcode ? `[${option.zipcode.substring(0, 2)}] ` : '';
            label += option.sport ? `[${option.sport}] ` : '';
            label += option.name;
            return (
              <Typography sx={{ fontSize: 14 }} {...props} key={option.id}>
                {label}
              </Typography>
            );
          }}
          renderInput={(params) => <TextField {...params} variant='standard' placeholder='Filtrer' />}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              const tagProps = getTagProps({ index });
              tagProps.key = index;
              return (
                <Chip
                  variant='outlined'
                  label={option.name}
                  size='small'
                  {...tagProps}
                  key={option.id}
                  sx={{ borderColor: props.color, backgroundColor: 'white' }}
                />
              );
            })
          }
          noOptionsText={t('no-result')}
          disablePortal
          sx={{
            '.MuiInput-root': {
              fontSize: 14,
              fontFamily: 'RobotoRegular',
            },
            '.MuiInput-root:before': {
              borderBottomColor: props.color,
            },
            '.MuiInput-root:after': {
              borderBottomColor: props.color,
            },
            '.MuiInput-root:hover': {
              borderBottomColor: props.color,
            },
            '& + .MuiAutocomplete-popper .MuiAutocomplete-option': {
              '&[aria-selected="true"]': {
                backgroundColor: props.color,
              },
              '&.Mui-focused': {
                backgroundColor: '#ebebeb',
              },
            },
          }}
        />
        {/* <Select
          value={props.value ? props.value : []}
          id='grouped-select'
          multiple
          onChange={props.handleChange}
          renderValue={(listSelectedIndex: any) => {
            return listSelectedIndex.map(
              (item: string, index: number) => dataByValueKey[item] && <p key={index}>{dataByValueKey[item].name}</p>,
            );
          }}
          sx={{
            borderRadius: '5px',
            backgroundColor: 'white',
            border: `1px solid ${props.color}`,
            '.MuiSvgIcon-root ': {
              // to hide text if it's too long
              backgroundColor: 'white',
            },
            '&:hover': {
              border: `2px solid ${props.color}`,
            },
            '&.Mui-focused fieldset': {
              border: 'none',
            },
            '.MuiOutlinedInput-notchedOutline': {
              border: 0,
            },
            '& .MuiSelect-multiple': {
              maxWidth: '385px',
              fontFamily: 'RobotoRegular',
              fontSize: 14,
              padding: '5px',
              paddingLeft: '15px',
            },
          }}
        >
          {props.data.map((value: any) => (
            <MenuItem
              key={value.id}
              value={props.valueKey === ValueKeyEnum.NAME ? value.name : value.id}
              sx={{
                '&.Mui-selected': {
                  backgroundColor: props.color,
                  '&:hover': { backgroundColor: 'lightGrey' },
                },
                fontSize: 14,
              }}
            >
              {value.beginDatetime && `[${dateFormatDDMMAA(new Date(value.beginDatetime))}] `}
              {value.name && value.name}
            </MenuItem>
          ))}
        </Select> */}
      </FormControl>
    </div>
  );
}
