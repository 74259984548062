import React from 'react';
import './Credit.scss';
import LayoutPrincipal from '../component/LayoutPrincipal';
import { useTranslation } from 'react-i18next';
import { useGetCreditBalanceQuery, useGetCreditHistoryQuery } from '../services/CreditApi';
import CreditSummary, { CreditTypeEnum } from '../component/CreditSummary';

export default function Credit() {
  const { t } = useTranslation();

  const creditHistory = useGetCreditHistoryQuery();
  const campaigns = creditHistory.data;
  const getCreditBalance = useGetCreditBalanceQuery();
  const creditBalance = getCreditBalance.data && getCreditBalance.data.creditBalance;

  return (
    <LayoutPrincipal
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
      }}
      titleStep={t('credit.my-credit')}
    >
      <p style={{ width: '80vw', textAlign: 'center' }}>{t('credit.spec')}</p>

      {creditHistory && creditHistory.data ? (
        <>
          <p className='sum'>
            {t('credit.sum')} : {creditBalance} €
          </p>

          <h2 className='recapTitle'>{t('credit.history')}</h2>
          {campaigns &&
            campaigns.map((campaign, index) =>
              campaign.creditBalanceEarned > 0 && campaign.creditBalanceUsed > 0 ? (
                <div key={index}>
                  <CreditSummary
                    type={CreditTypeEnum.EARNED}
                    label={campaign.name}
                    date={campaign.closedAt as string}
                    credit={campaign.creditBalanceEarned}
                    campaignId={campaign.id}
                    isCreditNote={campaign.creditNote}
                  />
                  <CreditSummary
                    type={CreditTypeEnum.USED}
                    label={campaign.name}
                    date={campaign.paidAt as string}
                    credit={campaign.creditBalanceUsed}
                    campaignId={campaign.id}
                    isCreditNote={campaign.creditNote}
                  />
                </div>
              ) : campaign.creditBalanceUsed > 0 ? (
                <div key={index}>
                  <CreditSummary
                    type={CreditTypeEnum.USED}
                    label={campaign.name}
                    date={campaign.paidAt as string}
                    credit={campaign.creditBalanceUsed}
                    campaignId={campaign.id}
                    isCreditNote={campaign.creditNote}
                  />
                </div>
              ) : (
                <div key={index}>
                  <CreditSummary
                    type={CreditTypeEnum.EARNED}
                    label={campaign.name}
                    date={campaign.closedAt as string}
                    credit={campaign.creditBalanceEarned}
                    campaignId={campaign.id}
                    isCreditNote={campaign.creditNote}
                  />
                </div>
              ),
            )}
        </>
      ) : (
        <p className='sum'>{t('credit.no-credit')}</p>
      )}
    </LayoutPrincipal>
  );
}
