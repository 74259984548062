import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

interface MainRadioProps {
  choices: {
    value: string;
    label: string;
  }[];
  label?: string;
  color?: string;
  size?: number;
  value?: string;
  name: string;
  onChange?: (arg?: any) => void;
  onBlur?: (arg?: any) => void;
}

export default function MainRadio(props: MainRadioProps) {
  const labels = props.choices.map((choice, index) => (
    <FormControlLabel
      key={index}
      sx={{
        color: props.color ?? 'black',
      }}
      value={choice.value}
      control={
        <Radio
          sx={{
            color: props.color ?? 'black',
            '&.Mui-checked': {
              color: props.color ?? 'black',
            },
          }}
        />
      }
      label={choice.label}
    />
  ));

  return (
    <FormControl>
      <div
        style={{
          display: 'inline-flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '400px',
        }}
      >
        {props.label && (
          <FormLabel
            style={{
              color: props.color ? props.color : 'black',
              fontFamily: 'RobotoBold',
            }}
          >
            {props.label}
          </FormLabel>
        )}
        <RadioGroup
          row
          aria-label='position'
          name={props.name}
          value={props.value}
          onChange={props.onChange}
          onBlur={props.onBlur}
        >
          {labels}
        </RadioGroup>
      </div>
    </FormControl>
  );
}
