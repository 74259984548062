export interface User {
  avertiserAccount?: string;
  email: string;
  password?: string;
}

export interface Address {
  country: string;
  city: string;
  zipcode: string;
  street: string;
}

export enum userTypeEnum {
  CIE = 'company',
  ASSO = 'association',
}

export interface AdvertiserAccount {
  campaignManager: string;
  companyName: string;
  companyType: userTypeEnum;
  phoneNumber: string;
  siretSiren: string;
  vatNumber: string;
  address: Address;
}

export interface UserAccount {
  advertiserAccount: AdvertiserAccount;
  user: User;
}
