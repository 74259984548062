import { PartnerEnum } from './ad';

export enum CouponType {
  PERCENTAGE = 'percentage_budget_discount',
  FIXED = 'fixed_budget_discount',
  IMPRESSIONS = 'free_impressions',
  CLICKS = 'free_clicks',
}

export interface Coupon {
  id: string;
  type: CouponType;
  distributionPartner: PartnerEnum;
  discountAmount?: number;
  numberOfFreeUnits?: number;
  message?: string;
  affiliate?: string;
  associatedClub?: string;
  couponCode?: string;
  name?: string;
}
