import { createApi } from '@reduxjs/toolkit/query/react';
import { CampaignCreditHistory } from '../models/campaign';
import { baseQueryWithReauth } from './BaseQuery';

export const creditApi = createApi({
  reducerPath: 'creditApi',
  baseQuery: baseQueryWithReauth,

  endpoints: (builder) => ({
    getCreditHistory: builder.query<CampaignCreditHistory[], void>({
      query: () => `/credit_balance_history/`,
      transformResponse: (resp: CampaignCreditHistory[]) => {
        const sortedCampaigns = resp.sort((c1, c2) => {
          const c1Date = c1.closedAt ? new Date(c1.closedAt) : new Date(c1.paidAt);
          const c2Date = c2.closedAt ? new Date(c2.closedAt) : new Date(c2.paidAt);
          return c2Date.valueOf() - c1Date.valueOf();
        });
        return sortedCampaigns;
      },
    }),

    getCreditBalance: builder.query<{ creditBalance: number }, void>({
      query: () => `/credit_balance/`,
    }),
  }),
});

export const { useGetCreditHistoryQuery, useGetCreditBalanceQuery } = creditApi;
export const { endpoints, reducerPath, reducer, middleware } = creditApi;
