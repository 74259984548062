import React from 'react';

import './AdOverview.scss';
import { PartnerEnum, PlacementTypeEnum } from '../models/ad';
import ReactPlayer from 'react-player';

import PortraitPhoneBanner from './../assets/icons/OverviewPortraitBanner.svg';
import PortraitPhoneStatistic from './../assets/icons/OverviewPortraitStatistic.svg';
import LandscapePhoneVideo from './../assets/icons/OverviewLandscapeVideo.svg';

import AdBanniereTP from './../assets/icons/Banniere_TP.jpg';
import AdInterstitielTP from './../assets/icons/Interstitiel_TP.jpg';
import AdPaveTP from './../assets/icons/Pave_TP.jpg';

interface AdOverviewProps {
  image?: string;
  isLandscape?: boolean;
  placementType?: PlacementTypeEnum;
  isVideo?: boolean;
  width?: number;
  pointerEvents?: 'none' | 'auto';
  partner?: PartnerEnum;
}

export default function AdOverview(props: AdOverviewProps) {
  const bgUrl = (() => {
    switch (props.placementType) {
      case PlacementTypeEnum.STATISTICS_MEDIA:
        return props.partner === PartnerEnum.TP ? AdPaveTP : PortraitPhoneStatistic;
      case PlacementTypeEnum.PERMANENT_BANNER:
        return props.partner === PartnerEnum.TP ? AdBanniereTP : PortraitPhoneBanner;
      case PlacementTypeEnum.INTERSTITIAL:
        return props.partner === PartnerEnum.TP ? AdInterstitielTP : PortraitPhoneBanner;
      default:
        return LandscapePhoneVideo;
    }
  })();

  const article_classname = (() => {
    switch (props.placementType) {
      case PlacementTypeEnum.STATISTICS_MEDIA:
        return props.partner === PartnerEnum.TP ? 'adStatsContainerTP' : 'adStatsContainer';
      case PlacementTypeEnum.PERMANENT_BANNER:
        return props.partner === PartnerEnum.TP ? 'adBannerContainerTP' : 'adBannerContainer';
      case PlacementTypeEnum.INTERSTITIAL:
        return 'adInterstitielContainer';
      default:
        return 'adLandscapeContainer';
    }
  })();

  const img_classname = (() => {
    switch (props.placementType) {
      case PlacementTypeEnum.STATISTICS_MEDIA:
        return 'adStats';
      case PlacementTypeEnum.PERMANENT_BANNER:
        return 'adBanner';
      case PlacementTypeEnum.INTERSTITIAL:
        return 'adInterstitiel';
      default:
        return 'adLandscapeContainer';
    }
  })();

  const stylePhoneContainer = {
    width: props.width ? props.width : 150,
    display: 'flex',
    backgroundImage: `url(${bgUrl})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    aspectRatio: '8/17',
    border: props.partner === PartnerEnum.TP ? 'solid 1px' : 'none',
  };

  const styleLandscapePhoneContainer = {
    width: props.width ? props.width : 280,
    display: 'flex',
    backgroundImage: `url(${bgUrl})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    aspectRatio: '17/8',
  };

  const styleChoice =
    props.placementType === PlacementTypeEnum.STATISTICS_MEDIA ||
    props.placementType === PlacementTypeEnum.PERMANENT_BANNER ||
    props.placementType === PlacementTypeEnum.INTERSTITIAL
      ? stylePhoneContainer
      : styleLandscapePhoneContainer;

  return (
    <section>
      <div style={styleChoice}>
        <article className={article_classname}>
          {props.isVideo ? (
            <ReactPlayer
              url={props.image}
              width={'90%'}
              height={'75%'}
              // pointerEvent permet de rendre la video cliquable ou non
              style={{
                pointerEvents: props.pointerEvents ? 'auto' : 'none',
              }}
            />
          ) : (
            <img src={props.image} className={img_classname} alt='publicité' />
          )}
        </article>
      </div>
    </section>
  );
}
