import axios from 'axios';

export async function verifyZipcodes(code: string) {
  const isZipcode = await axios
    .get(`https://geo.api.gouv.fr/communes?codePostal=${code}`)
    .then((resp) => {
      if (resp.data.length > 0) {
        return true;
      } else return false;
    })
    .catch((error) => {
      console.log(error);
      return true;
    });
  return isZipcode;
}
