import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const tokenApi = createApi({
  reducerPath: 'tokenApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_ROOT_URL || (window as any)._env_.REACT_APP_BACKEND_ROOT_URL}/`,
    prepareHeaders: (headers) => {
      headers.set('x-api-key', process.env.REACT_APP_API_KEY || (window as any)._env_.REACT_APP_API_KEY || '');
    },
  }),
  endpoints: (builder) => ({
    signInUser: builder.mutation({
      query: (form: { email: string; password: string }) => ({
        url: '/token',
        method: 'POST',
        body: form,
      }),
    }),
    refreshToken: builder.mutation({
      query: (form: { refresh: string }) => ({
        url: '/token/refresh',
        method: 'POST',
        body: form,
      }),
    }),
  }),
});

export const { useSignInUserMutation, useRefreshTokenMutation } = tokenApi;
export const { endpoints, reducerPath, reducer, middleware } = tokenApi;
