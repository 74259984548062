import React from 'react';
import { Button } from '@mui/material';
import { BrandColor } from '../helper/colorHelper';
import './MainButton.scss';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';

interface MainButtonProps {
  label?: string;
  width?: number;
  height?: number;
  backgroundColor?: string;
  borderColor?: string;
  labelColor?: string;
  style?: object;
  iconLeft?: any;
  iconRight?: any;
  onClickButton?: () => void;
  isLinkButton?: boolean;
  isTextButton?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
}

const StyledButton = styled(Button)({
  fontFamily: 'RobotoBold',
  marginTop: '20px',
  '&:hover': { filter: 'brightness(0.8)' },
});

export default function MainButton(props: MainButtonProps) {
  return !props.isLinkButton && !props.isTextButton ? (
    <StyledButton
      variant='contained'
      type='submit'
      onClick={props.onClickButton}
      disabled={props.disabled}
      style={{
        width: props.width ? props.width : 250,
        height: props.height ? props.height : 50,
        backgroundColor: props.backgroundColor ? props.backgroundColor : BrandColor,
      }}
    >
      {props.iconLeft && props.iconLeft}
      {props.isLoading ? (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        props.label && (
          <p
            style={{
              color: props.labelColor ? props.labelColor : 'white',
              margin: 0,
              lineHeight: 1.5,
            }}
          >
            {props.label}
          </p>
        )
      )}
      {props.iconRight && props.iconRight}
    </StyledButton>
  ) : (
    <div style={props.style} onClick={props.onClickButton}>
      {props.label && (
        <span
          style={{
            color: props.labelColor ? props.labelColor : BrandColor,
          }}
          className={props.isLinkButton ? 'buttonText linkStyle' : 'buttonText '}
        >
          {props.label}
        </span>
      )}
    </div>
  );
}
