import React from 'react';
import { createBrowserRouter, Outlet, Navigate } from 'react-router-dom';
import ParametersAd from './ParametersAd';
import SignInAndSignUp from './SignInAndSignUp';
import UserCampaigns from './UserCampaigns';
import CampaignDetails from './CampaignDetails';
import NewAd from './NewAd';
import Payment from './Payment';
import ResetPassword from './ResetPassword';
import Credit from './Credit';
import Account from './Account';
import CampaignResults from './CampaignResults';
import HasValidToken from '../helper/hasValidToken';

const ProtectedRoutes = () => {
  const isConnected = HasValidToken();
  return isConnected ? <Outlet /> : <Navigate to='/login' />;
};

const HomePageManager = () => {
  const isConnected = HasValidToken();
  return isConnected ? <Navigate to='/campaigns' replace /> : <Navigate to='/login' replace />;
};

const router = createBrowserRouter([
  { path: '/', element: <HomePageManager /> },
  { path: '/login', element: <SignInAndSignUp /> },
  { path: '/login/reset-password/:token/:email', element: <ResetPassword /> },
  {
    element: <ProtectedRoutes />,
    children: [
      { path: '/account', element: <Account /> },
      {
        path: '/campaigns',
        element: <UserCampaigns />,
      },
      { path: '/campaign-details', element: <CampaignDetails /> },
      { path: '/campaign-results', element: <CampaignResults /> },
      { path: '/new-ad', element: <NewAd /> },
      { path: '/parameters-ad', element: <ParametersAd /> },
      { path: '/payment', element: <Payment /> },
      { path: '/credit', element: <Credit /> },
    ],
  },
]);

export default router;
