import React, { useState, KeyboardEvent, useEffect } from 'react';
import './ParametersAd.scss';
import { GreyColor, handlePartnerColor } from '../helper/colorHelper';

import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import type { RootState } from '../store';
import LayoutPrincipal from '../component/LayoutPrincipal';
import MainButton from '../component/MainButton';
import MainInput from '../component/MainInput';
import MainSelectList, { ValueKeyEnum, dataType } from '../component/MainSelectList';
import MainCheckBox from '../component/MainCheckBox';
import departementData from '../data/departements.json';
import agesIntervalCP from '../data/agesIntervalCP.json';
import agesIntervalTP from '../data/agesIntervalTP.json';

import { FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';

import {
  recoverAge,
  recoverBroadcastTimeMatch,
  recoverSports,
  recoverClubs,
  recoverZipcodes,
  recoverDpt,
  recoverMatchs,
  recoverSexe,
  recoverBillPriceExcl,
} from '../slice/CampaignSlice';
import { ICampaignRedux } from '../models/campaign';
import { IAdRedux, SexeEnum, PlacementTypeEnum, PartnerEnum, Age, OptionBroadcastTime } from '../models/ad';
import { Sport } from '../models/sport';

import { useGetMatchsQuery } from '../services/MatchApi';
import { useGetClubsQuery } from '../services/ClubApi';
import { useGetSportsQuery } from '../services/SportApi';
import { verifyZipcodes } from '../services/zipcodesService';
import { useGetTpInventaryQuery, useGetTpSportsQuery } from '../services/TpInventaryApi';

import { getMaxTpBudgetWithViews, getViewsWithBudget } from '../helper/priceHelper';
import { isZipcodeFormat } from '../helper/zipcodeHelper';

export default function ParametersAd() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const currentAd: IAdRedux | undefined = useSelector((state: RootState) =>
    state.campaign.ads?.find((ad: IAdRedux) => ad.id === state.campaign.currentAdId),
  );

  const currentCampaign: ICampaignRedux = useSelector((state: RootState) => state.campaign);

  const partner = currentAd?.partner;
  const brandColor = handlePartnerColor(partner);

  const agesInterval = partner === PartnerEnum.TP ? agesIntervalTP : agesIntervalCP;

  const [matchSelected, setMatchSelected] = useState<string[]>(
    currentAd?.public && currentAd.public.matchs ? currentAd.public.matchs : [],
  );

  const [clubsSelected, setClubsSelected] = useState<string[]>(
    currentAd?.public && currentAd.public.clubs ? currentAd.public.clubs : [],
  );

  const handleSportName = (sports: Sport[]) => {
    return sports.map((sport) => sport.name);
  };

  const [sportsSelected, setSportsSelected] = useState<Sport[]>(
    currentAd?.public && currentAd.public.sports ? currentAd.public.sports : [],
  );

  const [broadcastTimeMatchOptions, setBroadcastTimeMatchOptions] = useState<OptionBroadcastTime[]>(
    currentAd?.public.time_match && currentAd.public.time_match ? currentAd.public.time_match : [],
  );

  const [department, setDepartment] = useState<string[]>(
    currentAd?.public && currentAd.public.departments ? currentAd.public.departments : [],
  );

  const [postalCode, setPostalCode] = useState<string[]>(
    currentAd?.public && currentAd.public.zipcodes ? currentAd.public.zipcodes : [],
  );
  const [errorPostal, setErrorPostal] = useState<string>();

  const [agesSelected, setAgesSelected] = useState<Age[]>(
    currentAd?.public && currentAd.public.age ? currentAd.public.age : [],
  );

  const [sexeSelected, setSexeSelected] = useState<SexeEnum | undefined>(
    currentAd?.public && currentAd.public.sexe ? currentAd.public.sexe : SexeEnum.ANY,
  );

  const [maximumViews, setMaximumViews] = useState<number>(
    currentAd?.billPriceExcl && currentAd?.placementType
      ? getViewsWithBudget(currentAd.placementType, currentAd.billPriceExcl)
      : 0,
  );

  const [budget, setBudget] = useState<number>(currentAd ? currentAd.billPriceExcl : 0);

  const clubsQuery = useGetClubsQuery(null, { skip: partner !== PartnerEnum.CP });
  const sportsQuery = useGetSportsQuery(null, { skip: partner !== PartnerEnum.CP });
  const matchesQuery = useGetMatchsQuery(
    {
      start_date: currentCampaign.startDatetime,
      end_date: currentCampaign.endDatetime,
    },
    { skip: partner !== PartnerEnum.CP },
  );

  const tpSportsQuery = useGetTpSportsQuery(null, { skip: partner !== PartnerEnum.TP });

  // find 2 digit dep code
  const countiesCode =
    partner === PartnerEnum.TP && department
      ? department.map((depName) => {
          return departementData.filter((dep) => dep.name === depName)[0].id;
        })
      : [];

  const sports = sportsSelected ? sportsSelected.map((sport) => sport.id).join() : '';
  const ages = agesSelected ? agesSelected.map((age) => age.id).join() : '';

  const tpInventary = useGetTpInventaryQuery(
    { sports: sports, postcodes: postalCode.join(), counties: countiesCode.join(), ages: ages },
    { skip: partner !== PartnerEnum.TP },
  );

  const maxBudgetCP = currentAd?.placementType === PlacementTypeEnum.PERMANENT_BANNER ? 1000 : 500;
  const [maxBudgetTP, setMaxBudgetTP] = useState<number>(0);
  const maxBudget = partner === PartnerEnum.TP ? maxBudgetTP : maxBudgetCP;

  useEffect(() => {
    if (tpInventary.data) {
      let budgetTP;
      if (currentAd?.placementType === PlacementTypeEnum.PERMANENT_BANNER) {
        budgetTP = getMaxTpBudgetWithViews(
          PlacementTypeEnum.PERMANENT_BANNER,
          tpInventary.data.banners_impressions.monthly,
        );
      } else if (currentAd?.placementType === PlacementTypeEnum.INTERSTITIAL) {
        budgetTP = getMaxTpBudgetWithViews(
          PlacementTypeEnum.INTERSTITIAL,
          tpInventary.data.interstitials_impressions.monthly,
        );
      } else if (currentAd?.placementType === PlacementTypeEnum.STATISTICS_MEDIA) {
        budgetTP = getMaxTpBudgetWithViews(
          PlacementTypeEnum.STATISTICS_MEDIA,
          tpInventary.data.mrec_impressions.monthly,
        );
      }
      (budgetTP || budgetTP === 0) && setMaxBudgetTP(budgetTP > 5000 ? 5000 : budgetTP);
    }
  }, [currentAd?.placementType, tpInventary.data]);

  function deleteAgeItem(ageId: string) {
    const newAges = agesSelected.filter((age) => age.id !== ageId);
    setAgesSelected(newAges);
    dispatch(recoverAge(newAges));
  }

  function deleteBroadcastTimeItem(currentBroadcastTime: OptionBroadcastTime) {
    const copyBroadcastTimeMatchOptions = [...broadcastTimeMatchOptions];
    const newBroadcastTimeMatchOptions = copyBroadcastTimeMatchOptions.filter(
      (e: OptionBroadcastTime) => e !== currentBroadcastTime,
    );
    setBroadcastTimeMatchOptions(newBroadcastTimeMatchOptions);
    dispatch(recoverBroadcastTimeMatch(newBroadcastTimeMatchOptions));
  }

  const handleEnterPostalCode = async (e: KeyboardEvent) => {
    const element = e.target as HTMLInputElement;
    if (e.key === 'Enter' && element.value) {
      // verification of the code format before calling API
      if (element.value.length != 5) {
        setErrorPostal(t('error.postal-code'));
      } else {
        // format is ok, we call api
        const isZipcode = isZipcodeFormat(element.value) ? await verifyZipcodes(element.value) : false;
        if (isZipcode) {
          setErrorPostal('');
          setPostalCode([...postalCode, element.value]);
          dispatch(recoverZipcodes([...postalCode, element.value]));
          element.value = '';
        } else {
          setErrorPostal(t('error.postal-code'));
        }
      }
    }
  };

  const handleDeletePostalCode = (index: number) => {
    const newPostalCodeArray = Array.from(postalCode);
    newPostalCodeArray.splice(index, 1);
    setPostalCode(newPostalCodeArray);
    dispatch(recoverZipcodes(newPostalCodeArray));
  };

  // not called for now, waiting for app to be ready
  const diffusionOptions = (
    <section className='optionContainer'>
      <h3>Options de diffusion</h3>
      <p className='descriptionItem'>{t('description.option-broadcast-time')}</p>
      <div className='checkBoxContainer'>
        <MainCheckBox
          label={'Diffusion avant le début du match (+20% du CPM)'}
          onSelectItem={(isSelected) => {
            if (isSelected) {
              const newBroadcastTimeMatchOptions = [...broadcastTimeMatchOptions, OptionBroadcastTime.BEFORE];
              setBroadcastTimeMatchOptions(newBroadcastTimeMatchOptions);
              dispatch(recoverBroadcastTimeMatch(newBroadcastTimeMatchOptions));
            } else {
              deleteBroadcastTimeItem(OptionBroadcastTime.BEFORE);
            }
          }}
          initialValue={
            broadcastTimeMatchOptions.find(
              (broadcastTime: OptionBroadcastTime) => broadcastTime === OptionBroadcastTime.BEFORE,
            )
              ? true
              : false
          }
        />
        <MainCheckBox
          label={'Diffusion pendant la mi-temps du match (+10% du CPM)'}
          onSelectItem={(isSelected) => {
            if (isSelected) {
              const newBroadcastTimeMatchOptions = [...broadcastTimeMatchOptions, OptionBroadcastTime.DURING];
              setBroadcastTimeMatchOptions(newBroadcastTimeMatchOptions);
              dispatch(recoverBroadcastTimeMatch(newBroadcastTimeMatchOptions));
            } else {
              deleteBroadcastTimeItem(OptionBroadcastTime.DURING);
            }
          }}
          initialValue={
            broadcastTimeMatchOptions.find(
              (broadcastTime: OptionBroadcastTime) => broadcastTime === OptionBroadcastTime.DURING,
            )
              ? true
              : false
          }
        />
        <MainCheckBox
          label={'Diffusion à la fin du match (+20% du CPM)'}
          onSelectItem={(isSelected) => {
            if (isSelected) {
              const newBroadcastTimeMatchOptions = [...broadcastTimeMatchOptions, OptionBroadcastTime.AFTER];
              setBroadcastTimeMatchOptions(newBroadcastTimeMatchOptions);
              dispatch(recoverBroadcastTimeMatch(newBroadcastTimeMatchOptions));
            } else {
              deleteBroadcastTimeItem(OptionBroadcastTime.AFTER);
            }
          }}
          initialValue={
            broadcastTimeMatchOptions.find(
              (broadcastTime: OptionBroadcastTime) => broadcastTime === OptionBroadcastTime.AFTER,
            )
              ? true
              : false
          }
        />
      </div>
    </section>
  );

  const diffusionContextChamps = (
    <section className='optionContainer'>
      <h3 style={{ color: brandColor }}> {t('advert.context-broadcast')} </h3>
      <p className='descriptionItem'>{t('description.options-context-cp')}</p>
      <p className='descriptionItem'>{t('description.options-match-stopped')}</p>
      <p className='titleInput'> {t('advert.select-sport')} </p>
      <p className='descriptionItem' style={{ marginTop: '-10px' }}>
        {t('description.options-context-sport')}
      </p>
      <MainSelectList
        valueKey={ValueKeyEnum.NAME}
        value={handleSportName(sportsSelected)}
        multiple
        data={sportsQuery.isSuccess && sportsQuery.data ? sportsQuery.data : []}
        handleValueChange={(event, value) => {
          setSportsSelected(value as Sport[]);
          dispatch(recoverSports(value as Sport[]));
        }}
        color={brandColor}
      />
      <p className='titleInput'> {t('advert.select-club')} </p>
      <p className='descriptionItem' style={{ marginTop: '-10px' }}>
        {t('description.options-context-club')}
      </p>
      <MainSelectList
        valueKey={ValueKeyEnum.ID}
        value={clubsSelected}
        multiple
        data={clubsQuery.isSuccess && clubsQuery.data ? clubsQuery.data : []}
        handleValueChange={(event, value) => {
          const clubs = value ? (value as dataType[]).map((club) => club.id as string) : [];
          setClubsSelected(clubs);
          dispatch(recoverClubs(clubs));
        }}
        color={brandColor}
      />
      <p className='titleInput'> {t('advert.select-match')} </p>
      <p className='descriptionItem' style={{ marginTop: '-10px' }}>
        {t('description.options-context-match')}
      </p>
      <MainSelectList
        valueKey={ValueKeyEnum.ID}
        value={matchSelected}
        multiple
        handleValueChange={(event, value) => {
          const matches = value ? (value as dataType[]).map((match) => match.id as string) : [];
          setMatchSelected(matches);
          dispatch(recoverMatchs(matches));
        }}
        data={matchesQuery.isSuccess && matchesQuery.data ? matchesQuery.data : []}
        color={brandColor}
      />
    </section>
  );

  const diffusionContextTeampulse = (
    <section className='optionContainer'>
      <h3 style={{ color: brandColor }}>{t('advert.context-broadcast')} </h3>
      <p className='descriptionItem'>{t('description.options-context-tp')}</p>
      <p className='descriptionItem'>{t('description.options-match-stopped')}</p>
      <p className='titleInput'> {t('advert.select-sport')} </p>
      <p className='descriptionItem' style={{ marginTop: '-10px' }}>
        {t('description.options-context-sport')}
      </p>
      <MainSelectList
        valueKey={ValueKeyEnum.NAME}
        value={handleSportName(sportsSelected)}
        multiple
        data={tpSportsQuery.isSuccess && tpSportsQuery.data ? tpSportsQuery.data : []}
        handleValueChange={(event, value) => {
          setSportsSelected(value as Sport[]);
          dispatch(recoverSports(value as Sport[]));
        }}
        color={brandColor}
      />
      <p className='titleInput'> {t('advert.select-departement')} </p>
      <p className='descriptionItem' style={{ marginTop: '-10px' }}>
        {t('description.options-context-department')}
      </p>
      <MainSelectList
        valueKey={ValueKeyEnum.NAME}
        value={department}
        multiple
        handleValueChange={(_, value) => {
          const newDepartments = value ? (value as dataType[]).map((dpt) => dpt.name) : [];
          setDepartment(newDepartments);
          dispatch(recoverDpt(newDepartments));
        }}
        data={departementData}
        color={brandColor}
      />
      <p className='titleInput'> {t('advert.select-postcodes')} </p>
      <p className='descriptionItem' style={{ marginTop: '-10px' }}>
        {t('description.options-context-zipcode')}
      </p>
      <p className='descriptionItem'>
        <em> {t('press-to-validate')} </em>
      </p>
      <TextField
        type={'tel'}
        onKeyPress={handleEnterPostalCode}
        placeholder={'ex: 75000'}
        name={'postalCode'}
        size='small'
        inputProps={{ maxLength: 5 }}
        sx={{
          width: 450,
          '& .MuiInputBase-input': {
            fontFamily: 'RobotoRegular',
            fontSize: 14,
            paddingLeft: 1,
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: brandColor,
              borderTop: '0px',
              borderRight: '0px',
              borderLeft: '0px',
              borderRadius: '0px',
            },
            '&:hover fieldset': {
              borderColor: brandColor,
            },
            '&.Mui-focused fieldset': {
              borderColor: brandColor,
            },
          },
        }}
      />
      {errorPostal && <p className='formError'>{errorPostal}</p>}
      {postalCode && postalCode.length > 0 && (
        <ul className='postalCodes'>
          {postalCode.map((code, index) => (
            <li key={index}>
              {code}
              <button name='delete postal code' onClick={() => handleDeletePostalCode(index)}>
                x
              </button>
            </li>
          ))}
        </ul>
      )}
    </section>
  );

  return (
    <LayoutPrincipal
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
      }}
      titleStep={t('title.broadcast-settings')}
      isShowingVideoTuto={true}
    >
      <div className='publicAndOptionsContainer'>
        {partner === PartnerEnum.TP ? diffusionContextTeampulse : diffusionContextChamps}
        <article className='rightOptionsContainer'>
          <section className='optionContainer'>
            <h3 style={{ color: brandColor }}> {t('target-audience')} </h3>
            <p className='descriptionItem'>{t('description.options-public-target')}</p>
            <p className='titleInput'> {t('advert.select-age-range')} </p>
            <div className='checkBoxContainer'>
              {agesInterval &&
                agesInterval.length > 0 &&
                agesInterval.map((interval, index) => {
                  return (
                    <MainCheckBox
                      key={'AgeCheckBox' + index}
                      label={interval.name}
                      color={brandColor}
                      onSelectItem={(isSelected) => {
                        if (isSelected) {
                          const newAges = [...agesSelected, { id: interval.id, name: interval.name }];
                          setAgesSelected(newAges);
                          dispatch(recoverAge(newAges));
                        } else {
                          deleteAgeItem(interval.id);
                        }
                      }}
                      initialValue={agesSelected.find((age) => age.id === interval.id) ? true : false}
                    />
                  );
                })}
            </div>
            <FormControl>
              <p className='titleInput'>Sexe</p>

              <RadioGroup
                aria-labelledby='demo-radio-buttons-group-label'
                defaultValue={SexeEnum.ANY}
                name='radio-buttons-group'
              >
                <div className='checkBoxContainer'>
                  <FormControlLabel
                    value={SexeEnum.M}
                    control={
                      <Radio
                        sx={{
                          color: brandColor,
                          '&.Mui-checked': {
                            color: brandColor,
                          },
                        }}
                        checked={sexeSelected === SexeEnum.M}
                      />
                    }
                    label={t('man')}
                    onClick={() => {
                      setSexeSelected(SexeEnum.M);
                      dispatch(recoverSexe(SexeEnum.M));
                    }}
                  />
                  <FormControlLabel
                    value={SexeEnum.F}
                    control={
                      <Radio
                        sx={{
                          color: brandColor,
                          '&.Mui-checked': {
                            color: brandColor,
                          },
                        }}
                        checked={sexeSelected === SexeEnum.F}
                      />
                    }
                    label={t('woman')}
                    onClick={() => {
                      setSexeSelected(SexeEnum.F);
                      dispatch(recoverSexe(SexeEnum.F));
                    }}
                  />
                  <FormControlLabel
                    value={SexeEnum.ANY}
                    control={
                      <Radio
                        sx={{
                          color: brandColor,
                          '&.Mui-checked': {
                            color: brandColor,
                          },
                        }}
                        checked={sexeSelected === SexeEnum.ANY}
                      />
                    }
                    label={t('any')}
                    onClick={() => {
                      setSexeSelected(SexeEnum.ANY);
                      dispatch(recoverSexe(SexeEnum.ANY));
                    }}
                  />
                </div>
              </RadioGroup>
            </FormControl>
          </section>

          <section className='optionContainer'>
            <h3 style={{ color: brandColor }}> {t('advert.select-max-view')} </h3>
            <p className='descriptionItem'>
              <Trans
                i18nKey='description.option-broadcast-maximum'
                values={{ maxCampaign: partner === PartnerEnum.TP ? '5000' : '1000' }}
              />
            </p>
            <div className='budgetContainer'>
              <p style={{ color: brandColor }}>Budget (€ HT)</p>
              <MainInput
                name='budget'
                type='text'
                color={brandColor}
                inputProps={{
                  sx: {
                    textAlign: 'right',
                    '&::placeholder': {
                      textAlign: 'right',
                    },
                  },
                }}
                onChange={(e) => {
                  // regex to prevent user typing anything else than a number, and remove 0 in first character
                  const validPrice = e.target.value.replace(/^0+|[^\d.]/g, '');
                  // round to prevent user entering price with decimal, and buying XXX,X views
                  setBudget(Math.round(validPrice));
                  currentAd?.placementType &&
                    setMaximumViews(getViewsWithBudget(currentAd.placementType, Math.round(validPrice)));
                }}
                value={budget}
                width={'220px'}
              />
            </div>
            <div className='budgetHelperContainer'>
              <p
                style={{
                  color: 'grey',
                  textAlign: 'left',
                  width: '180px',
                }}
              >
                {maxBudget < 7 ? t('advert.budget-min') : t('advert.budget-limit') + maxBudget + '€'}
              </p>

              {budget !== 0 && maxBudget > 7 && (budget < 7 || budget > maxBudget) && (
                <p
                  style={{
                    color: 'red',
                    textAlign: 'center',
                    width: '220px',
                    fontSize: 9,
                    paddingTop: 3,
                  }}
                >
                  {partner === PartnerEnum.CP ? t('error.budget-cp') + maxBudget + '€' : t('error.budget-tp')}
                </p>
              )}

              {maxBudget < 7 && (
                <p
                  style={{
                    color: 'red',
                    textAlign: 'center',
                    width: '220px',
                    fontSize: 9,
                    paddingTop: 3,
                  }}
                >
                  {t('advert.no-inventary')}
                </p>
              )}
            </div>
            <div className='budgetContainer'>
              <p style={{ color: brandColor }}>{t('advert.views-number')}</p>
              <MainInput
                name='views'
                type='text'
                color={brandColor}
                isDisabled={true}
                width={'220px'}
                inputProps={{
                  sx: {
                    textAlign: 'right',
                  },
                }}
                value={maximumViews}
              />
            </div>

            <p className='descriptionItem'>
              {partner === PartnerEnum.CP ? t('description.view-help-cp') : t('description.view-help-tp')}
            </p>
          </section>
          <section className='optionContainer'></section>
          <div
            style={{
              width: '450px',
              textAlign: 'right',
            }}
          >
            <p className='total' style={{ color: brandColor, fontWeight: 'bold' }}>
              Total
            </p>
            <p className='total'>{budget}€ HT</p>
            <MainButton
              backgroundColor={budget < 7 || budget > maxBudget ? GreyColor : brandColor}
              disabled={budget < 7 || budget > maxBudget}
              label={t('advert.finalize')}
              onClickButton={() => {
                dispatch(recoverBillPriceExcl(budget));
                navigate('/campaign-details');
              }}
            />
          </div>
        </article>
      </div>
    </LayoutPrincipal>
  );
}
