import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const resetPasswordApi = createApi({
  reducerPath: 'resetPasswordApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_ROOT_URL || (window as any)._env_.REACT_APP_BACKEND_ROOT_URL}/`,
    prepareHeaders: (headers) => {
      headers.set('x-api-key', process.env.REACT_APP_API_KEY || (window as any)._env_.REACT_APP_API_KEY || '');
    },
  }),

  endpoints: (builder) => ({
    reqResetPassword: builder.mutation({
      query: (data: { email: string }) => ({
        url: 'request_reset_password/',
        method: 'POST',
        body: data,
      }),
    }),
    checkResetPassword: builder.mutation({
      query: (data: { email: string; token: string }) => ({
        url: 'check_reset_password_token/',
        method: 'POST',
        body: data,
      }),
    }),
    resetPassword: builder.mutation({
      query: (data: { email: string; token: string; password1: string; password2: string }) => ({
        url: 'reset_password/',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useReqResetPasswordMutation, useCheckResetPasswordMutation, useResetPasswordMutation } =
  resetPasswordApi;
export const { endpoints, reducerPath, reducer, middleware } = resetPasswordApi;
