import React from 'react';
import './CampaignResults.scss';
import { useSelector } from 'react-redux';
import { ICampaignRedux } from '../models/campaign';
import { RootState } from '../store';
import { Trans, useTranslation } from 'react-i18next';
import LayoutPrincipal from '../component/LayoutPrincipal';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  styled,
  tableCellClasses,
  TableCell,
} from '@mui/material';
import { BrandColor } from '../helper/colorHelper';
import { dateFormatDDMMAA } from '../helper/dateHelper';
import { IAdRedux, PartnerEnum, PlacementTypeEnum } from '../models/ad';
import { getTTCPrice, roundNumber } from '../helper/priceHelper';
import { DownloadFilesButton } from '../component/DownloadFilesButton';

function CampaignResults() {
  const { t } = useTranslation();

  const currentCampaign: ICampaignRedux = useSelector((state: RootState) => state.campaign);

  const StyledTableCell = styled(TableCell)({
    padding: '5px',
    textAlign: 'center',
    height: '55px',
    borderBottom: 0,
    borderLeft: `2px solid ${BrandColor}`,
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: BrandColor,
      color: 'white',
      lineHeight: '20px',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  });

  const StyledTableRow = styled(TableRow)({
    '&:nth-of-type(even)': {
      backgroundColor: '#64dcbe33',
    },
  });

  const handleZero = (value: number | undefined | null) => {
    if (value === 0 || value === undefined || value === null) {
      return 0;
    } else return value;
  };

  // only handle the case of a fixed coupon for now
  const totalCouponPrice = currentCampaign.ads
    .map((ad) => handleZero(ad.billPriceExcl) - handleZero(ad.reducedBillPriceExcl))
    .reduce((total, adDiscount) => total + adDiscount);

  const totalCampaignPrice = currentCampaign.ads.reduce(
    (total, ad) => (ad.billPriceExcl ? total + ad.billPriceExcl : total + 0),
    0,
  );

  const tva =
    currentCampaign.reducedBillPriceIncl &&
    currentCampaign.reducedBillPriceExcl &&
    currentCampaign.reducedBillPriceIncl / currentCampaign.reducedBillPriceExcl;

  return (
    <LayoutPrincipal
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
      }}
      isShowingVideoTuto={false}
      titleStep={t('title.results') + currentCampaign.name}
    >
      <p className='campaignDate'>
        {t('start-campaign')}
        {currentCampaign.startDatetime && dateFormatDDMMAA(new Date(currentCampaign.startDatetime))}
        {currentCampaign.closedAt && ' ' + t('end-campaign') + dateFormatDDMMAA(new Date(currentCampaign.closedAt))}
      </p>
      <TableContainer sx={{ marginTop: '40px' }}>
        <Table
          sx={{ width: '90vw', marginLeft: 'auto', marginRight: 'auto', border: `3px solid ${BrandColor}` }}
          aria-label='campaign results table'
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>{t('result-table.media')}</StyledTableCell>
              <StyledTableCell>{t('result-table.partner')}</StyledTableCell>
              <StyledTableCell>{t('result-table.ad-type')}</StyledTableCell>
              <StyledTableCell>
                {/* used or Trans to allow elements like <br> to work if included in translation */}
                <Trans i18nKey='result-table.paid-views' />
              </StyledTableCell>
              <StyledTableCell>
                <Trans i18nKey='result-table.impressions' />
              </StyledTableCell>
              <StyledTableCell>{t('result-table.clicks')}</StyledTableCell>
              <StyledTableCell>
                <Trans i18nKey='result-table.price' />
              </StyledTableCell>
              <StyledTableCell>{t('discount')}</StyledTableCell>
              <StyledTableCell>
                <Trans i18nKey='result-table.paid-price' />
              </StyledTableCell>
              <StyledTableCell>
                <Trans i18nKey='result-table.used-price' />
              </StyledTableCell>
              <StyledTableCell>{t('credit.credit')}</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {currentCampaign.ads.map((ad) => (
              <StyledTableRow key={ad.id}>
                <StyledTableCell component='th' scope='row'>
                  <img src={ad.media} alt='ad media overview' className='tableMedia' />
                </StyledTableCell>
                <StyledTableCell>{ad.partner === PartnerEnum.TP ? 'TeamPulse' : "CHAMP'S"}</StyledTableCell>
                <StyledTableCell>{ad.placementType && t(ad.placementType)}</StyledTableCell>
                <StyledTableCell sx={{ width: '100px' }}>{ad.fixedQuantity}</StyledTableCell>
                <StyledTableCell sx={{ width: '100px' }}>{ad.impressions}</StyledTableCell>
                <StyledTableCell sx={{ width: '65px' }}>{ad.clics && ad.clics > 0 ? ad.clics : '-'} </StyledTableCell>
                <StyledTableCell sx={{ width: '65px' }}> {ad.billPriceExcl} €</StyledTableCell>
                <StyledTableCell sx={{ width: '65px' }}>
                  {handleZero(ad.billPriceExcl) - handleZero(ad.reducedBillPriceExcl) > 0
                    ? roundNumber(handleZero(ad.billPriceExcl) - handleZero(ad.reducedBillPriceExcl)) + '€'
                    : '-'}
                </StyledTableCell>
                <StyledTableCell sx={{ width: '65px' }}> {ad.reducedBillPriceExcl} €</StyledTableCell>
                <StyledTableCell sx={{ width: '65px' }}> {ad.reducedBillPriceExclConsumed} €</StyledTableCell>
                <StyledTableCell sx={{ width: '65px', color: BrandColor, fontWeight: 'bold' }}>
                  {roundNumber(handleZero(ad.reducedBillPriceExcl) - handleZero(ad.reducedBillPriceExclConsumed))} €
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>

        {currentCampaign.coupons.length > 0 && (
          <div className='couponTable'>
            {currentCampaign.coupons.map((coupon) => (
              <p key={coupon.id}>
                {t('result-table.coupon')} &quot;{coupon.message}&quot; {t('on')}{' '}
                {coupon.distributionPartner === PartnerEnum.TP ? 'TeamPulse' : "CHAMP'S"}
              </p>
            ))}
          </div>
        )}

        <section className='endContainer total'>
          <div>Total</div>
          <div>{roundNumber(totalCampaignPrice)} €</div>
          <div>{totalCouponPrice > 0 ? roundNumber(totalCouponPrice) + ' €' : '-'}</div>
          <div>{currentCampaign.reducedBillPriceExcl} €</div>
          <div>
            {roundNumber(
              handleZero(currentCampaign.reducedBillPriceExcl) - handleZero(currentCampaign.creditBalanceEarned),
            )}
            €
          </div>
          <div>{currentCampaign.creditBalanceEarned ? currentCampaign.creditBalanceEarned : 0} €</div>
        </section>

        <section className='endContainer total'>
          <div>Total TTC</div>
          <div>{getTTCPrice(totalCampaignPrice, tva)} €</div>
          <div> - </div>
          <div>{currentCampaign.reducedBillPriceIncl} €</div>
          <div>
            {getTTCPrice(
              handleZero(currentCampaign.reducedBillPriceExcl) - handleZero(currentCampaign.creditBalanceEarned),
              tva,
            )}
            €
          </div>
          <div>-</div>
        </section>
        <section className='endContainer'>
          <DownloadFilesButton
            campaignId={currentCampaign.idApi}
            campaignClosed={currentCampaign.closedAt}
            invoice={!!currentCampaign.invoice}
            orderForm={!!currentCampaign.orderForm}
            creditNote={!!currentCampaign.creditNote}
            deliveryNote={!!currentCampaign.deliveryNote}
          />
        </section>
      </TableContainer>
    </LayoutPrincipal>
  );
}

export default CampaignResults;
