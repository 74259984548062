import React from 'react';
import { PartnerEnum } from '../models/ad';
import { handlePartnerColor } from '../helper/colorHelper';
import ChampsLogo from '../assets/icons/champs-logo-button.svg';
import TeampLogo from '../assets/icons/teampulse-logo-long.svg';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { t } from 'i18next';

interface AddButtonProps {
  label?: string;
  onClickAddButton?: () => void;
  partner?: PartnerEnum;
}

export default function AddButton(props: AddButtonProps) {
  const brandColor = handlePartnerColor(props.partner);
  const { t } = useTranslation();

  return (
    <article>
      <button
        type='button'
        className='newCampaign'
        style={{ color: brandColor }}
        onClick={() => {
          props.onClickAddButton && props.onClickAddButton();
        }}
      >
        +
      </button>
      <div style={{ width: '150', textAlign: 'center', display: 'flex', alignItems: 'center' }}>
        <div style={{ minWidth: '100%' }}>
          <p className='sub' style={{ color: brandColor }}>
            {props.label}
          </p>
          {props.partner === PartnerEnum.TP && (
            <img
              src={TeampLogo}
              alt='logo partenaire'
              style={{
                width: '120px',
                marginTop: '-5px',
              }}
            />
          )}
          {props.partner === PartnerEnum.CP && (
            <img
              src={ChampsLogo}
              alt='logo partenaire'
              style={{
                width: '100px',
                marginTop: '-15px',
              }}
            />
          )}
        </div>
        {props.partner === PartnerEnum.TP && (
          <div style={{ marginTop: '15px' }}>
            <Tooltip
              placement='right'
              sx={{
                color: brandColor,
              }}
              componentsProps={{
                tooltip: {
                  sx: {
                    backgroundColor: brandColor,
                    marginLeft: '10px',
                  },
                },
              }}
              title={
                <p>
                  <Trans i18nKey='tp-tooltip' />
                  <br />
                  <a
                    style={{ fontWeight: 'bold', textDecoration: 'None', color: 'black' }}
                    target='_blank'
                    href='https://www.champs.fr/communiquez-sur-team-pulse-grace-a-la-regie-pub-de-champs/'
                    rel='noreferrer'
                  >
                    {t('tp-tooltip-more')}
                  </a>
                </p>
              }
            >
              <InfoIcon></InfoIcon>
            </Tooltip>
          </div>
        )}
      </div>
    </article>
  );
}
