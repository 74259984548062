import {
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query';
import { RootState } from '../store';
import { clearToken, newToken } from '../slice/TokenSlice';

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_BACKEND_ROOT_URL || (window as any)._env_.REACT_APP_BACKEND_ROOT_URL}/api/`,
  prepareHeaders: (headers, { getState }) => {
    headers.set('x-api-key', process.env.REACT_APP_API_KEY || (window as any)._env_.REACT_APP_API_KEY || '');
    const token = (getState() as RootState).token;
    if (token) {
      headers.set('Authorization', `Bearer ${token.access}`);
    }
    return headers;
  },
});

const tokenQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_BACKEND_ROOT_URL || (window as any)._env_.REACT_APP_BACKEND_ROOT_URL}/token/`,
  prepareHeaders: (headers) => {
    headers.set('x-api-key', process.env.REACT_APP_API_KEY || (window as any)._env_.REACT_APP_API_KEY || '');
  },
});

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  any,
  FetchBaseQueryMeta
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    const token = (api.getState() as RootState).token;

    const refreshToken = await tokenQuery(
      {
        url: `refresh`,
        method: 'POST',
        body: { refresh: token.refresh },
      },
      api,
      extraOptions,
    );
    if (refreshToken.data) {
      const data = refreshToken.data as { access: string };
      api.dispatch(newToken({ access: data.access, refresh: token.refresh }));
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(clearToken());
      window.location.replace('/login');
    }
  }
  return result;
};
