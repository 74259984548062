import { IAdAPI, IAdRedux, IAdSubmitAPI } from './ad';
import { Coupon } from './coupon';

export enum PaymentStatus {
  PENDING = 'pending',
  PAID = 'paid',
  REFUSED = 'refused',
  CANCELLED = 'cancelled',
  BLOCKED = 'blocked',
  REFUNDED = 'refunded',
}

export interface ICampaignRedux {
  name: string;
  idApi?: string;
  currentAdId: number;
  ads: IAdRedux[];
  lastUpdate: string;
  startDatetime: string;
  endDatetime?: string;
  closedAt?: string;
  isCampaignFromAPI?: boolean;
  paymentStatus?: PaymentStatus;
  coupons: Coupon[];
  creditBalanceUsed: number;
  creditBalanceEarned?: number;
  reducedBillPriceExcl?: number;
  reducedBillPriceIncl?: number;
  invoice?: boolean;
  orderForm?: boolean;
  creditNote?: boolean;
  deliveryNote?: boolean;
}

interface IPartialCampaign {
  name: string;
  createdAt?: any;
  startDatetime: string;
  endDatetime?: string;
  id?: string;
  creditBalanceUsed: number;
}

export interface ICampaignFetchAPI extends IPartialCampaign {
  ads: IAdAPI[];
  paymentStatus?: PaymentStatus;
  id?: string;
  coupons: Coupon[];
  creditBalanceEarned: number;
  closedAt?: string;
  reducedBillPriceExcl?: number;
  reducedBillPriceIncl?: number;
  invoice?: boolean;
  orderForm?: boolean;
  creditNote?: boolean;
  deliveryNote?: boolean;
}

export interface ICampaignAPI extends IPartialCampaign {
  ads: IAdSubmitAPI[];
  coupons: string[];
}

export interface CampaignCreditHistory {
  id: string;
  name: string;
  paidAt: string;
  closedAt?: string;
  creditBalanceEarned: number;
  creditBalanceUsed: number;
  creditNote: boolean;
}

export interface FileParams {
  campaingId?: string | undefined;
  fileName?: string | undefined;
}

export interface Vads {
  signature: string;
  vads_action_mode: string;
  vads_amount: string;
  vads_capture_delay: string;
  vads_ctx_mode: string;
  vads_currency: string;
  vads_cust_address: string;
  vads_cust_address2?: string;
  vads_cust_cell_phone: string;
  vads_cust_city: string;
  vads_cust_country: string;
  vads_cust_email: string;
  vads_cust_first_name?: string;
  vads_cust_id: string;
  vads_cust_last_name?: string;
  vads_cust_legal_name: string;
  vads_cust_state?: string;
  vads_cust_status: string;
  vads_cust_title?: string;
  vads_cust_zip: string;
  vads_order_id: string;
  vads_page_action: string;
  vads_payment_config: string;
  vads_site_id: string;
  vads_trans_date: string;
  vads_trans_id: string;
  vads_validation_mode: string;
  vads_version: string;
}
