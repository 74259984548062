import { createSlice } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';
import { deleteTokenToLocalStorage, registerTokenToLocalStorage } from '../helper/localStorage/localStorageTokenHelper';

export interface ITokenRedux {
  access: string;
  refresh: string;
  advertiserAccount: string;
}

export interface DecodeToken {
  advertiser_account: string;
  exp: number;
  token_type: string;
  user_id: string;
}

const initialState: ITokenRedux = JSON.parse(localStorage.getItem('token') + '')
  ? JSON.parse(localStorage.getItem('token') + '')
  : {
      access: '',
      refresh: '',
      advertiserAccount: '',
    };

export const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    newToken: (state, action: { payload: { access: string; refresh: string } }) => {
      const decodeToken: DecodeToken = jwt_decode(action.payload.access);
      const token: ITokenRedux = {
        access: action.payload.access,
        refresh: action.payload.refresh,
        advertiserAccount: decodeToken.advertiser_account,
      };
      registerTokenToLocalStorage(token);
      return (state = token);
    },
    clearToken: (state) => {
      deleteTokenToLocalStorage();
      return (state = {
        access: '',
        refresh: '',
        advertiserAccount: '',
      });
    },
  },
});

export const { newToken, clearToken } = tokenSlice.actions;

export default tokenSlice.reducer;
