import React, { useState } from 'react';
import './SignInAndSignUp.scss';
import flagFR from './../assets/icons/french-flag.svg';
import flagEN from './../assets/icons/uk-flag.svg';
import ChampsLogoHeader from './../assets/icons/logo-regie.svg';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { BrandColor } from '../helper/colorHelper';
import { checkMailformat } from '../helper/emailHelper';
import { isPhoneFormat } from '../helper/phoneHelper';
import { isZipcodeFormat } from '../helper/zipcodeHelper';
import { useSignInUserMutation } from '../services/TokenApi';
import { newToken } from '../slice/TokenSlice';
import { useCreateAdvertiserUserMutation } from '../services/UserApi';
import { useReqResetPasswordMutation } from '../services/ResetPasswordApi';
import { UserAccount, userTypeEnum } from '../models/user';

import { Formik } from 'formik';
import MainButton from '../component/MainButton';
import MainInput from '../component/MainInput';
import MainModal from '../component/MainModal';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import MainCheckBox from '../component/MainCheckBox';
import MainRadio from '../component/MainRadio';

import { verifyZipcodes } from '../services/zipcodesService';

interface ConnectionForm {
  email: string;
  password: string;
}
interface ConnectionFormErrors {
  email?: string;
  password?: string;
}

interface SignUpFormErrors {
  user?: { email?: string; password?: string };
  advertiserAccount?: {
    campaignManager?: string;
    companyName?: string;
    companyType?: string;
    phoneNumber?: string;
    siretSiren?: string;
    vatNumber?: string;
    address?: {
      country?: string;
      city?: string;
      zipcode?: string;
      street?: string;
    };
  };
}

export default function SignInAndSignUp() {
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [errorApi, setErrorApi] = useState<string>();
  const [errorMail, setErrorMail] = useState<string>();
  const [errorApiAdvertiserAccount, setErrorApiAdvertiserAccount] = useState<string>();
  const [showPasswordSignIn, setShowPasswordSignIn] = useState<boolean>(false);
  const [showPasswordSignUp, setShowPasswordSignUp] = useState<boolean>(false);
  const [showModalSignUp, setShowModalSignUp] = useState<boolean>(false);
  const [showModalResetPwd, setShowModalResetPwd] = useState<boolean>(false);
  const [userMail, setUserMail] = useState<string>('');
  const [isFormComplete, setIsFormComplete] = useState<boolean>(false);
  const [cgvAccepted, setCgvAccepted] = useState<boolean>(false);

  const { t } = useTranslation();
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [signIn] = useSignInUserMutation();
  const [createAdvertiserAccount] = useCreateAdvertiserUserMutation();
  const [resetPassword] = useReqResetPasswordMutation();

  const [resetMessage, setResetMessage] = useState<string>('');

  const handleConnection = async (form: ConnectionForm) => {
    setErrorApi('');
    const { error, data }: any = await signIn(form);

    if (error) {
      if (error.data && error.data.detail) {
        setErrorApi(t(error.data.detail));
      } else if (error.data && error.data.user) {
        setErrorApi(t(error.data.user.email[0]));
      } else {
        setErrorApi(t('error.general'));
      }
      setIsloading(false);
    }
    if (data && data.access && data.refresh) {
      await dispatch(newToken({ refresh: data.refresh, access: data.access }));
      return navigate('/campaigns');
    }
    setIsloading(false);
    return;
  };

  const handleResetPwd = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (checkMailformat(userMail)) {
      setErrorMail(t('error.email-format'));
    } else {
      const { error }: any = await resetPassword({ email: userMail });
      if (error && error.data && error.data.error) {
        setResetMessage(error.data.error);
        setIsloading(false);
      } else if (error) {
        setResetMessage(t('error.general'));
        setIsloading(false);
        console.log(error);
      } else {
        setResetMessage(t('reset-password'));
        setIsloading(false);
      }
    }
  };

  async function handleSubmitAdvertiserAccount(form: UserAccount) {
    const isZipcode = await verifyZipcodes(form.advertiserAccount.address.zipcode);
    if (!isZipcode) {
      const errors: SignUpFormErrors = {
        advertiserAccount: {
          address: {
            zipcode: t('error.postal-code'),
          },
        },
      };
      setErrorApiAdvertiserAccount(t('error.general-form'));

      setIsloading(false);
      return errors;
    } else {
      const { error }: any = await createAdvertiserAccount(form);
      if (error) {
        setIsloading(false);
        if (error.data) {
          const userError = error.data.user;
          const accountError = error.data.advertiserAccount;

          if (userError && userError.general) {
            setErrorApiAdvertiserAccount(t('error.general'));
          } else {
            setErrorApiAdvertiserAccount(t('error.general-form'));
            const errors: SignUpFormErrors = {
              user: {
                email: userError?.email?.map((v: string) => t(v)).join(', ') || '',
                password: userError && userError.password ? userError.password.map((v: string) => t(v)).join(', ') : '',
              },
              advertiserAccount: {
                campaignManager: accountError?.companyManager?.map((v: string) => t(v)).join(', ') || '',
                companyName: accountError?.companyName?.map((v: string) => t(v)).join(', ') || '',
                companyType: accountError?.companyType?.map((v: string) => t(v)).join(', ') || '',
                phoneNumber: accountError?.phoneNumber?.map((v: string) => t(v)).join(', ') || '',
                vatNumber: accountError?.vatNumber?.map((v: string) => t(v)).join(', ') || '',
                siretSiren: accountError?.siretSiren?.map((v: string) => t(v)).join(', ') || '',
                address: {
                  street: accountError?.address?.street?.map((v: string) => t(v)).join(', ') || '',
                  zipcode: accountError?.address?.zipcode?.map((v: string) => t(v)).join(', ') || '',
                  country: accountError?.address?.country?.map((v: string) => t(v)).join(', ') || '',
                  city: accountError?.address?.city?.map((v: string) => t(v)).join(', ') || '',
                },
              },
            };
            return errors;
          }
        } else setErrorApiAdvertiserAccount(t('error.general'));
      } else {
        setIsloading(false);
        setShowModalSignUp(true);
      }
    }
  }

  return (
    <main className='homeContainer'>
      <MainModal
        isOpen={showModalSignUp}
        handleClose={() => {
          setShowModalSignUp(false);
          setIsloading(false);
        }}
      >
        <p style={{ margin: 0 }}>{t('confirm-by-email')}</p>

        <MainButton
          label={'OK'}
          onClickButton={() => {
            window.location.reload();
          }}
        />
      </MainModal>

      <MainModal
        isOpen={showModalResetPwd}
        handleClose={() => {
          setShowModalResetPwd(false);
          setIsloading(false);
          setUserMail('');
        }}
      >
        <p>{t('email-form')}</p>
        <form onSubmit={handleResetPwd} style={{ textAlign: 'center' }}>
          <MainInput
            isRequired={true}
            name='email'
            type='email'
            label={t('email')}
            onChange={(e) => setUserMail(e.target.value)}
            value={userMail}
            width={'320px'}
          />
          {errorMail && <p className='formError'>{errorMail}</p>}
          {resetMessage === t('reset-password') ? (
            <MainButton
              label={'OK'}
              onClickButton={() => {
                window.location.reload();
              }}
            />
          ) : (
            <MainButton label={t('validation')} isLoading={isLoading} onClickButton={() => setIsloading(true)} />
          )}

          {resetMessage && <p className='formError'>{resetMessage}</p>}
        </form>
      </MainModal>

      <section className='login'>
        <img src={ChampsLogoHeader} alt="logo régie publicitaire CHAMP'S" className='logoRegie' width='260px' />
        <div style={{ textAlign: 'center' }}>
          <p>{t('sign-in')}</p>
          <Formik
            initialValues={{ email: '', password: '' }}
            validate={(values) => {
              const errors: ConnectionFormErrors = {};
              if (checkMailformat(values.email)) {
                errors.email = t('error.email-format');
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setIsloading(true);
              handleConnection({ email: values.email, password: values.password });
              setSubmitting(false);
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit} className='loginForm'>
                <MainInput
                  isRequired={true}
                  name='email'
                  label={t('email')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  width={'320px'}
                />
                {errors.email && touched.email && <p className='formError'>{errors.email}</p>}
                <MainInput
                  isRequired={true}
                  type={showPasswordSignIn ? 'text' : 'password'}
                  name='password'
                  label={t('password')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  width={'320px'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={() => setShowPasswordSignIn(!showPasswordSignIn)}
                          edge='end'
                        >
                          {showPasswordSignIn ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {errors.password && touched.password && <p className='formError'>{errors.password}</p>}

                <MainButton label={t('login')} disabled={isSubmitting} isLoading={isLoading} />
                {errorApi && <p className='formError'>{errorApi}</p>}
              </form>
            )}
          </Formik>
          <button type='button' className='forgotPassword' onClick={() => setShowModalResetPwd(true)}>
            {t('forgot-password')}
          </button>
        </div>

        <div className='containerLangageButton'>
          <button type='button'>
            <img src={flagFR} alt='changer langue français' height='25px' onClick={() => changeLanguage('fr')} />
          </button>
          <button type='button'>
            <img src={flagEN} alt='change language english' height='25px' onClick={() => changeLanguage('en')} />
          </button>
        </div>
      </section>

      <section className='newAccount'>
        <p>{t('sign-up')}</p>
        <Formik
          initialValues={{
            user: { email: '', password: '' },
            advertiserAccount: {
              companyType: userTypeEnum.CIE,
              companyName: '',
              campaignManager: '',
              siretSiren: '',
              vatNumber: '',
              phoneNumber: '',
              address: { country: '', city: '', zipcode: '', street: '' },
            },
          }}
          validate={(values) => {
            if (
              values.user.email &&
              values.user.password &&
              values.advertiserAccount.companyType &&
              values.advertiserAccount.companyName &&
              values.advertiserAccount.campaignManager &&
              values.advertiserAccount.siretSiren &&
              values.advertiserAccount.vatNumber &&
              values.advertiserAccount.phoneNumber &&
              values.advertiserAccount.address.city &&
              values.advertiserAccount.address.country &&
              values.advertiserAccount.address.street &&
              values.advertiserAccount.address.zipcode
            ) {
              setIsFormComplete(true);
            } else {
              setIsFormComplete(false);
            }

            const errors: SignUpFormErrors = {};

            if (checkMailformat(values.user.email)) {
              errors.user = { ...errors.user, email: t('error.email-format') };
            }
            if (values.user.password.length < 8) {
              errors.user = { ...errors.user, password: t('error.password-length') };
            }
            if (!isPhoneFormat(values.advertiserAccount.phoneNumber)) {
              errors.advertiserAccount = { ...errors.advertiserAccount, phoneNumber: t('error.phone-format') };
            }
            if (!isZipcodeFormat(values.advertiserAccount.address.zipcode)) {
              errors.advertiserAccount = {
                ...errors.advertiserAccount,
                address: {
                  ...errors.advertiserAccount?.address,
                  zipcode: t('error.zipcode-format'),
                },
              };
            }

            return errors;
          }}
          onSubmit={async (values: UserAccount, { setSubmitting, setErrors }) => {
            setIsloading(true);
            const errors = await handleSubmitAdvertiserAccount(values);
            if (errors) {
              setErrors(errors);
              setIsloading(false);
            }
            setSubmitting(false);
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit} className='loginForm'>
              <MainInput
                isRequired={true}
                name='user.email'
                label={t('email')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.user.email}
              />
              {errors.user?.email && touched.user?.email && <p className='formError'>{errors.user.email}</p>}
              <MainInput
                isRequired={true}
                type={showPasswordSignUp ? 'text' : 'password'}
                name='user.password'
                label={t('password')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.user.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={() => setShowPasswordSignUp(!showPasswordSignUp)}
                        edge='end'
                      >
                        {showPasswordSignUp ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <p className='passwordSpec'>{t('new-account.password-spec')}</p>
              {errors.user?.password && touched.user?.password && <p className='formError'>{errors.user.password}</p>}
              <MainRadio
                label={t('status') + ' :'}
                color={'white'}
                name='advertiserAccount.companyType'
                choices={[
                  {
                    label: t('company'),
                    value: userTypeEnum.CIE,
                  },
                  {
                    label: t('asso'),
                    value: userTypeEnum.ASSO,
                  },
                ]}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.advertiserAccount.companyType}
              />
              <MainInput
                isRequired={true}
                name='advertiserAccount.companyName'
                label={t('new-account.company-name')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.advertiserAccount.companyName}
              />
              {errors.advertiserAccount?.companyName && touched.advertiserAccount?.companyName && (
                <p className='formError'>{errors.advertiserAccount.companyName}</p>
              )}
              <MainInput
                isRequired={true}
                name='advertiserAccount.siretSiren'
                label={t('new-account.siret')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.advertiserAccount.siretSiren}
              />
              {errors.advertiserAccount?.siretSiren && touched.advertiserAccount?.siretSiren && (
                <p className='formError'>{errors.advertiserAccount.siretSiren}</p>
              )}
              <MainInput
                isRequired={true}
                name='advertiserAccount.vatNumber'
                label={t('new-account.tva')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.advertiserAccount.vatNumber}
              />
              {errors.advertiserAccount?.vatNumber && touched.advertiserAccount?.vatNumber && (
                <p className='formError'>{errors.advertiserAccount.vatNumber}</p>
              )}
              <MainInput
                isRequired={true}
                name='advertiserAccount.campaignManager'
                label={t('new-account.boss-name')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.advertiserAccount.campaignManager}
              />
              {errors.advertiserAccount?.campaignManager && touched.advertiserAccount?.campaignManager && (
                <p className='formError'>{errors.advertiserAccount.campaignManager}</p>
              )}
              <MainInput
                isRequired={true}
                inputProps={{ maxLength: 10 }}
                type='tel'
                name='advertiserAccount.phoneNumber'
                label={t('new-account.phone')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.advertiserAccount.phoneNumber}
              />
              {errors.advertiserAccount?.phoneNumber && touched.advertiserAccount?.phoneNumber && (
                <p className='formError'>{errors.advertiserAccount.phoneNumber}</p>
              )}

              <MainInput
                isRequired={true}
                name='advertiserAccount.address.street'
                label={t('new-account.street')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.advertiserAccount.address.street}
              />
              {errors.advertiserAccount?.address?.street && touched.advertiserAccount?.address?.street && (
                <p className='formError'>{errors.advertiserAccount.address.street}</p>
              )}

              <div className='address'>
                <MainInput
                  width={'115px'}
                  inputProps={{ maxLength: 5 }}
                  type={'tel'}
                  isRequired={true}
                  name='advertiserAccount.address.zipcode'
                  label={t('new-account.zipcode')}
                  value={values.advertiserAccount.address.zipcode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <MainInput
                  width={'275px'}
                  isRequired={true}
                  name='advertiserAccount.address.city'
                  label={t('new-account.city')}
                  value={values.advertiserAccount.address.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              {errors.advertiserAccount?.address?.zipcode && touched.advertiserAccount?.address?.zipcode && (
                <p className='formError'>{errors.advertiserAccount.address.zipcode}</p>
              )}
              {errors.advertiserAccount?.address?.city && touched.advertiserAccount?.address?.city && (
                <p className='formError'>{errors.advertiserAccount.address.city}</p>
              )}

              <MainInput
                isRequired={true}
                name='advertiserAccount.address.country'
                label={t('new-account.country')}
                value={values.advertiserAccount.address.country}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.advertiserAccount?.address?.country && touched.advertiserAccount?.address?.city && (
                <p className='formError'>{errors.advertiserAccount.address.country}</p>
              )}

              <div style={{ display: 'flex', alignItems: 'center', columnGap: 5 }}>
                <MainCheckBox
                  label={t('accept-cgv')}
                  initialValue={false}
                  color={'white'}
                  onSelectItem={(isSelected) => (isSelected ? setCgvAccepted(true) : setCgvAccepted(false))}
                />
                <a
                  className='conditionButtonsText'
                  rel='noreferrer'
                  href='https://partenaires.champs.fr/communication/conditions-generales-de-vente-de-la-regie-publicitaire-en-vente-directe/'
                  target='_blank'
                >
                  {t('cgv')}
                </a>
              </div>

              <MainButton
                label={t('new-account.create-account')}
                labelColor={BrandColor}
                disabled={isSubmitting || !cgvAccepted || !isFormComplete}
                isLoading={isLoading}
                backgroundColor={cgvAccepted && isFormComplete ? 'white' : 'grey'}
              />
              {errorApiAdvertiserAccount && <p className='formError'>{errorApiAdvertiserAccount}</p>}
            </form>
          )}
        </Formik>
      </section>
    </main>
  );
}
