import IRule from './rule';
import { Sport } from './sport';

export enum PlacementTypeEnum {
  PERMANENT_BANNER = 'permanent_banner',
  STATISTICS_MEDIA = 'statistics_media',
  LIVEFEED_MEDIA = 'livefeed_media',
  INTERSTITIAL = 'interstitial',
}

export enum PartnerEnum {
  CP = 'champs',
  TP = 'teampulse',
}

export enum ValidationEnum {
  STANDING_BY = 'standing_by',
  PENDING = 'pending',
  REFUSED = 'refused',
  ACCEPTED = 'accepted',
}

export enum MediaType {
  VIDEO = 'video',
  IMAGE = 'image',
}

export enum SexeEnum {
  M = 'm',
  F = 'f',
  ANY = 'any',
}

export enum OptionBroadcastTime {
  BEFORE = 'beforeMatch',
  DURING = 'duringMatch',
  AFTER = 'afterMatch',
}

export interface Age {
  id: string;
  name: string;
}

export interface IAdRedux {
  id: number;
  idApi?: string;
  name?: string;
  placementType?: PlacementTypeEnum;
  media_id?: string;
  media_type?: MediaType;
  media?: string;
  uploaded_media?: string;
  fixedQuantity?: number;
  impressions?: number;
  clics?: number;
  billPriceExcl: number;
  reducedBillPriceExcl?: number;
  reducedBillPriceExclConsumed?: number;
  dest_url?: string;
  created_with_ambassador?: boolean;
  public: Public;
  partner?: PartnerEnum;
}

export interface IAdSubmitAPI {
  id?: string;
  name?: string;
  placementType?: PlacementTypeEnum;
  billPriceExcl: number;
  uploadedMedia?: string;
  uploadedMediaType?: MediaType;
  destUrl?: string;
  createdWithAmbassador?: boolean;
  partner?: PartnerEnum;
  public: PublicAPI;
}

export interface IAdAPI {
  ad: {
    id?: string;
    name?: string;
    placementType: PlacementTypeEnum;
    fixedQuantity?: number;
    billPriceExcl: number;
    uploadedMedia?: string;
    uploadedMediaUrl?: string;
    uploadedMediaType?: MediaType;
    destUrl?: string;
    partner?: PartnerEnum;
    validation: ValidationEnum;
    audience: { name: string; simpleRuleSet: IRule[] };
    reducedBillPriceExcl?: number;
    reducedBillPriceExclConsumed?: number;
  };
  adStatistics?: {
    impressions?: number;
    clicks?: number;
  };
}

export interface Public {
  sports?: Sport[];
  championships?: string[];
  clubs?: string[];
  matchs?: string[];
  time_match?: OptionBroadcastTime[];
  age?: Age[];
  sexe?: SexeEnum;
  close_to_match?: boolean;
  practice_current_sport?: boolean;
  bear_local_club?: boolean;
  bear_visitor_club?: boolean;
  zipcodes?: string[];
  departments?: string[];
}

export interface PublicAPI {
  sports?: string[];
  championships?: string[];
  matches?: string[];
  age?: string;
  gender?: SexeEnum;
  closeToMatch?: boolean;
  practiceCurrentSport?: boolean;
  bearLocalClub?: boolean;
  bearVisitorClub?: boolean;
  timeMatch?: OptionBroadcastTime[];
  clubs?: string[];
  zipcodes?: string[];
  departments?: string[];
}
