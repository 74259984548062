import React, { Dispatch, SetStateAction } from 'react';
import './AdDisplay.scss';
import AdOverview from './AdOverview';
import MainButton from './MainButton';
import { useNavigate } from 'react-router-dom';
import { PartnerEnum, PlacementTypeEnum } from '../models/ad';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { changeAd, dupplicateNewAd } from '../slice/CampaignSlice';
import CircularProgress from '@mui/material/CircularProgress';

interface AdDisplayProps {
  image: string;
  placementType?: PlacementTypeEnum;
  isVideo?: boolean;
  adType?: 'Display Stats' | 'Vidéo' | 'Pied de page' | 'Interstitial' | 'Coupure pub';
  adId: number;
  allowChanges?: boolean;
  paidImpresssions: number;
  impressions?: number;
  brandColor: string;
  setShowModalDelete: Dispatch<SetStateAction<boolean>>;
  recoverAdIdToDelete?: (arg: number) => void;
  partner?: PartnerEnum;
}

function AdDisplay(props: AdDisplayProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // value passed to the circular progress in percentage : paidViews is 100%
  const adViews =
    props.impressions && props.impressions > 0 ? Math.round((props.impressions * 100) / props.paidImpresssions) : 0;

  return (
    <article className='annonce'>
      <h3 style={{ color: props.brandColor }}>
        {props.isVideo ? 'Video' : props.placementType ? t(props.placementType) : '?'}
      </h3>
      <AdOverview
        image={props.image}
        partner={props.partner}
        placementType={props.placementType}
        isVideo={props.isVideo}
        width={props.isVideo || props.placementType === PlacementTypeEnum.LIVEFEED_MEDIA ? 280 : 140}
      />
      {props.allowChanges ? (
        <div className='adButtonContainer'>
          <MainButton
            label={t('modify')}
            width={100}
            height={30}
            onClickButton={() => {
              navigate('/new-ad');
              dispatch(changeAd(props.adId));
            }}
          />
          <MainButton
            label={t('copy')}
            width={100}
            height={30}
            onClickButton={() => {
              navigate('/new-ad');
              dispatch(dupplicateNewAd(props.adId));
            }}
          />
          <MainButton
            label={t('delete')}
            backgroundColor={'red'}
            width={100}
            height={30}
            onClickButton={() => {
              props.setShowModalDelete(true);
              props.recoverAdIdToDelete && props.recoverAdIdToDelete(props.adId);
            }}
          />
        </div>
      ) : (
        <div className='viewsContainer'>
          <div style={{ position: 'relative', width: 45, marginRight: 'auto', marginLeft: 'auto' }}>
            <CircularProgress
              variant='determinate'
              value={100}
              thickness={9}
              size={45}
              sx={{ color: 'rgb(230, 230, 230)' }}
            />
            <CircularProgress
              variant='determinate'
              sx={{
                color: props.brandColor,
                position: 'absolute',
                left: 0,
              }}
              thickness={9}
              size={45}
              value={adViews}
            />
          </div>
          <p className='views' style={{ color: props.brandColor }}>
            {t('advert.views-count')}
            <br /> {props.impressions && props.impressions > 0 ? props.impressions : 0} / {props.paidImpresssions}
          </p>
        </div>
      )}
    </article>
  );
}

export default AdDisplay;
