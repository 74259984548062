import React from 'react';
import '../container/Credit.scss';
import { useTranslation } from 'react-i18next';
import { dateFormatDDMMAA } from '../helper/dateHelper';
import { BrandColor } from '../helper/colorHelper';
import { getCampaignFile } from '../services/CampaignFile';
import DownloadIcon from '@mui/icons-material/Download';
import { Button, Tooltip } from '@mui/material';

export enum CreditTypeEnum {
  EARNED = 'earned',
  USED = 'used',
}

interface CreditSummaryProps {
  label: string;
  date: string;
  credit: number;
  type: CreditTypeEnum;
  campaignId: string;
  isCreditNote: boolean;
}

function CreditSummary(props: CreditSummaryProps) {
  const { t } = useTranslation();

  const downloadFiles = () => {
    getCampaignFile(props.campaignId, 'credit_note');
  };

  return (
    <section className='historyContainer'>
      {props.type === CreditTypeEnum.EARNED ? (
        <p>
          {t('campaign')} &quot;{props.label}&quot;, {t('credit.earned')} {dateFormatDDMMAA(new Date(props.date))}
        </p>
      ) : (
        <p>
          {t('campaign')} &quot;{props.label}&quot;, {t('credit.used')} {dateFormatDDMMAA(new Date(props.date))}
        </p>
      )}

      <div className='creditDots'></div>
      {props.type === CreditTypeEnum.USED ? (
        <>
          <p style={{ color: 'red' }}> - {props.credit} € </p>
          <span
            style={{
              marginRight: 70,
            }}
          ></span>
        </>
      ) : (
        <>
          <p style={{ color: BrandColor }}> + {props.credit} € </p>
          <Button
            disabled={!props.isCreditNote}
            onClick={() => downloadFiles()}
            style={{
              minWidth: 40,
              width: 40,
              height: 35,
              backgroundColor: !props.isCreditNote ? 'grey' : BrandColor,
              marginLeft: 30,
              marginTop: 'auto',
              marginBottom: -8,
            }}
          >
            <Tooltip title='Télécharger mon justificatif'>
              <DownloadIcon
                style={{
                  color: 'white',
                  transition: 'none',
                }}
              ></DownloadIcon>
            </Tooltip>
          </Button>
        </>
      )}
    </section>
  );
}

export default CreditSummary;
