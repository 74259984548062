import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function Payment() {
  const { state }: any = useLocation();

  useEffect(() => {
    if (state && state.vads) {
      const form = document.querySelector('form');
      form?.submit();
    }
  }, []);

  if (!state || !state.vads)
    return (
      <div>
        <p>Erreur lors de la récupération des informations de paiement.</p>
      </div>
    );
  else
    return (
      <div>
        <p>Redirection vers la plateforme de paiement sécurisée...</p>
        <form action='https://secure.payzen.eu/vads-payment/' method='post'>
          {Object.keys((state as any).vads).map((key) => (
            <input key={key} type='hidden' name={key} value={(state as any).vads[key]} />
          ))}
        </form>
      </div>
    );
}

export default Payment;
