import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import MainCheckBox from './MainCheckBox';
import './DownloadFilesButton.scss';
import { getCampaignFile } from '../services/CampaignFile';
import MainButton from './MainButton';
import { Trans, useTranslation } from 'react-i18next';
import loading from '../assets/icons/loading.svg';

interface DownloadFilesButtonProps {
  campaignId?: string;
  campaignClosed?: string | undefined;
  orderForm: boolean;
  invoice: boolean;
  creditNote: boolean;
  deliveryNote: boolean;
}

interface FileList {
  order_form: boolean;
  invoice: boolean;
  credit_note: boolean;
  delivery_note: boolean;
}

function WaitingFileIcon() {
  return (
    <img
      src={loading}
      alt='wait file'
      style={{
        minWidth: 30,
        width: 30,
      }}
    />
  );
}

export function DownloadFilesButton(props: DownloadFilesButtonProps) {
  const [files, setFiles] = React.useState<FileList>({
    order_form: false,
    invoice: false,
    credit_note: false,
    delivery_note: false,
  });

  const { t } = useTranslation();

  const [open, setOpen] = React.useState<boolean>(false);
  const [fileStatus, setFileStatus] = React.useState<{ status: number }>({ status: 200 });

  const downloadFiles = () => {
    for (const [file_name, isChecked] of Object.entries(files)) {
      if (isChecked) {
        const status = getCampaignFile(props.campaignId, file_name);
        status.then((value) => {
          setFileStatus(value);
        });
      }
    }
  };

  const disabled = !props.orderForm && !props.invoice && !props.creditNote && !props.deliveryNote;

  return (
    <div
      style={{
        justifyContent: 'right',
        display: 'flex',
      }}
    >
      <MainButton
        onClickButton={() => setOpen(true)}
        label={props.campaignClosed ? t('campaign_files.download_report') : t('campaign_files.download_receipt')}
        disabled={disabled}
        backgroundColor={disabled ? 'grey' : undefined}
      />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <DialogContentText
            style={{
              textAlign: 'center',
              marginBottom: '5%',
            }}
          >
            <Trans i18nKey='campaign_files.select_files' />
          </DialogContentText>
          <div id='CheckBoxList'>
            <div>
              <MainCheckBox
                label={t('campaign_files.order_form')}
                justifyContent='flex-end'
                color={'black'}
                initialValue={files.order_form}
                disabled={!props.orderForm}
                disabledIcon={<WaitingFileIcon />}
                onSelectItem={(isChecked) => {
                  setFiles((prevState) => ({
                    ...prevState,
                    order_form: isChecked,
                  }));
                }}
              />
              <MainCheckBox
                label={t('campaign_files.invoice')}
                justifyContent='flex-end'
                color={'black'}
                initialValue={files.invoice}
                disabled={!props.invoice}
                disabledIcon={<WaitingFileIcon />}
                onSelectItem={(isChecked) => {
                  setFiles((prevState) => ({
                    ...prevState,
                    invoice: isChecked,
                  }));
                }}
              />
              {props.campaignClosed && (
                <>
                  <MainCheckBox
                    label={t('campaign_files.delivery_note')}
                    justifyContent='flex-end'
                    color={'black'}
                    initialValue={files.delivery_note}
                    disabled={!props.deliveryNote}
                    disabledIcon={<WaitingFileIcon />}
                    onSelectItem={(isChecked) => {
                      setFiles((prevState) => ({
                        ...prevState,
                        delivery_note: isChecked,
                      }));
                    }}
                  />
                  {props.creditNote && (
                    <MainCheckBox
                      label={t('campaign_files.credit_note')}
                      justifyContent='flex-end'
                      color={'black'}
                      initialValue={files.credit_note}
                      onSelectItem={(isChecked) => {
                        setFiles((prevState) => ({
                          ...prevState,
                          credit_note: isChecked,
                        }));
                      }}
                    />
                  )}
                </>
              )}
            </div>
            {fileStatus.status !== 200 && <p style={{ color: 'red' }}>{t('error.general')}</p>}
          </div>
          <div
            style={{
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <MainButton
              onClickButton={() => downloadFiles()}
              label={t('campaign_files.download_file')}
              width={155}
              height={35}
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
