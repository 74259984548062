import React from 'react';
import ChampsLogoHeader from './../assets/icons/logo-regie.svg';
import ChampsLogoFooter from './../assets/icons/champs-logo-footer.svg';
import flagFR from './../assets/icons/french-flag.svg';
import flagEN from './../assets/icons/uk-flag.svg';
import voucher from './../assets/icons/voucher.svg';
import avatar from './../assets/icons/avatar.svg';
import logout from './../assets/icons/logout.svg';
import './LayoutPrincipal.scss';
import { Trans, useTranslation } from 'react-i18next';
import i18n from '../i18n';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetCampaign } from '../slice/CampaignSlice';
import VideoTutoTag from './VideoTutoTag';
import { clearToken } from '../slice/TokenSlice';

interface LayoutPrincipal {
  onClickPreviousStep?: () => void;
  onClickNextStep?: () => void;
  labelPreviousStep?: string;
  children: any;
  titleStep?: string;
  style?: object;
  hideLogOutButton?: boolean;
  userName?: string;
  isShowingVideoTuto?: boolean;
  isShowingAmbassadorHelp?: boolean;
}

export default function LayoutPrincipal(props: LayoutPrincipal) {
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className='mainContainer'>
      <header className='headerLayoutContainer'>
        <section className='headerButtons'>
          <button onClick={() => navigate('/account')}>
            <img src={avatar} alt='aller à mon compte' className='headerPicto' />
            <p className='pictoSubtitle'>{t('account').toUpperCase()}</p>
          </button>
          <button onClick={() => navigate('/credit')}>
            <img src={voucher} alt='consulter mes avoirs' className='headerPicto' />
            <p className='pictoSubtitle'>{t('credit.my-credit').toUpperCase()}</p>
          </button>
        </section>

        <img
          src={ChampsLogoHeader}
          alt="logo régie publicitaire CHAMP'S"
          width='220px'
          onClick={() => navigate('/campaigns')}
          style={{ cursor: 'pointer' }}
        />

        <section className='headerButtons'>
          <button
            onClick={() => {
              dispatch(resetCampaign());
              dispatch(clearToken());
              navigate('/login');
            }}
          >
            <img src={logout} alt='se deconnecter' className='headerPicto' />
            <p className='pictoSubtitle'>{t('logout').toUpperCase()}</p>
          </button>
        </section>
      </header>

      {props.isShowingVideoTuto ? (
        <div className='titleAndvideoTutoContainer'>
          <div style={{ width: '270px' }}>
            {props.isShowingAmbassadorHelp && (
              <a
                href='https://www.champs.fr/ambassadeurs-champs-pub'
                target='blank'
                style={{
                  textDecoration: 'none',
                }}
              >
                <div className='ambassadorBox'>
                  <div className='ambassadorNestedText'>
                    <Trans
                      i18nKey='ambassador-help'
                      components={{
                        b: <b />,
                      }}
                    ></Trans>
                  </div>
                </div>
              </a>
            )}
          </div>
          {props.titleStep && (
            <div
              style={{
                height: props.isShowingAmbassadorHelp ? '152px' : '110px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <p className='titleStep'>{props.titleStep}</p>
            </div>
          )}
          <div
            style={{
              width: '270px',
              height: props.isShowingAmbassadorHelp ? '152px' : '110px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <VideoTutoTag />
          </div>
        </div>
      ) : (
        <div>{props.titleStep && <p className='titleStep'>{props.titleStep}</p>}</div>
      )}

      <div style={props.style}>{props.children}</div>

      <footer className='footerLayoutContainer'>
        <a href='https://www.champs.fr/' target='_blank' rel='noreferrer'>
          <img src={ChampsLogoFooter} alt="logo CHAMP'S" height='110px' />
        </a>

        <section>
          <div className='conditionButtons'>
            <a
              className='conditionButtonsText'
              href='https://www.champs.fr/politique-de-confidentialite/'
              target='_blank'
              rel='noreferrer'
            >
              {t('confidentiality')}
            </a>

            <p className='separateButtonsText'>-</p>

            <a
              className='conditionButtonsText'
              href='https://www.champs.fr/mentions-legales/'
              target='_blank'
              rel='noreferrer'
            >
              {t('legal')}
            </a>

            <p className='separateButtonsText'>-</p>

            <a
              className='conditionButtonsText'
              rel='noreferrer'
              href='https://partenaires.champs.fr/communication/conditions-generales-de-vente-de-la-regie-publicitaire-en-vente-directe/'
              target='_blank'
            >
              {t('cgv')}
            </a>
          </div>
          <p className='rightsReserved'>{t('champs-right')}</p>
        </section>
        <section className='languageButtons'>
          <button type='button'>
            <img src={flagFR} alt='changer langue français' height='25px' onClick={() => changeLanguage('fr')} />
          </button>
          <button type='button'>
            <img src={flagEN} alt='change language english' height='25px' onClick={() => changeLanguage('en')} />
          </button>
        </section>
      </footer>
    </div>
  );
}
