import { createApi } from '@reduxjs/toolkit/query/react';
import { IClubAPI } from '../models/club';
import { baseQueryWithReauth } from './BaseQuery';

export const clubApi = createApi({
  reducerPath: 'clubApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getClubs: builder.query<IClubAPI[], null>({
      query: () => 'club/',
      transformResponse: (resp: IClubAPI[]) => {
        const clubs = resp.sort((c1, c2) => {
          if (c1.zipcode > c2.zipcode) {
            return 1;
          } else if (c1.zipcode < c2.zipcode) {
            return -1;
          } else {
            return c1.sport > c2.sport ? 1 : -1;
          }
        });
        return clubs;
      },
    }),
  }),
});

export const { useGetClubsQuery } = clubApi;
export const { endpoints, reducerPath, reducer, middleware } = clubApi;
