function dateFormatDDMM(date: Date) {
  return date.toLocaleDateString('FR-fr').slice(0, 5);
}

function dateFormatDDMMAA(date: Date) {
  return date.toLocaleDateString('FR-fr').slice(0, 10);
}

function compareDate(startDate: string, endDate: string) {
  const startDateFormat = Date.parse(startDate.slice(8, 10) + startDate.slice(4, 7) + startDate.slice(11, 33));
  const endDateFormat = Date.parse(endDate.slice(8, 10) + endDate.slice(4, 7) + endDate.slice(11, 33));
  return startDateFormat < endDateFormat;
}

function isSameDate(previousDate: string, date: string) {
  const previousDateFormat = Date.parse(previousDate.slice(8, 10) + previousDate.slice(4, 7) + date.slice(11, 33));
  const dateFormat = Date.parse(date.slice(8, 10) + date.slice(4, 7) + date.slice(11, 33));
  return previousDateFormat === dateFormat;
}

function getHHMM(date: Date) {
  const hours: string = date.getHours() > 9 ? date.getHours().toString() : `0${date.getHours().toString()}`;
  const minutes = date.getMinutes() > 9 ? date.getMinutes().toString() : `0${date.getMinutes().toString()}`;
  return hours + 'h' + minutes;
}

function minEndDate_calculation(new_date: Date | undefined, start_date: string, min_campaign_date: string) {
  const minimal = new Date(new_date || start_date || min_campaign_date);
  return new Date(Date.UTC(minimal.getFullYear(), minimal.getMonth(), minimal.getDate() + 29))
    .toISOString()
    .slice(0, 10);
}

export { dateFormatDDMM, compareDate, isSameDate, getHHMM, dateFormatDDMMAA, minEndDate_calculation };
