import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './BaseQuery';
import { Sport } from '../models/sport';

export const sportApi = createApi({
  reducerPath: 'sportApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getSports: builder.query<Sport[], null>({
      query: () => 'sport/',
      transformResponse: (resp: Sport[]) => {
        const sports = resp.sort((s1, s2) => (s1.name > s2.name ? 1 : -1));
        return sports;
      },
    }),
  }),
});

export const { useGetSportsQuery } = sportApi;
export const { endpoints, reducerPath, reducer, middleware } = sportApi;
