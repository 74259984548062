import React, { useState } from 'react';
import { Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player/file';
import CloseTag from '../assets/icons/close-tag.svg';
import OpenTag from '../assets/icons/open-tag.svg';
import { BrandColor } from '../helper/colorHelper';
import VideoTuto from '../assets/tutoVideo/tutoRegiePubv3.mp4';
import './VideoTutoTag.scss';

interface VideoTutoProps {
  video?: string;
  style?: object;
  width?: string;
  height?: string;
}

export default function VideoTutoTag(props: VideoTutoProps) {
  const { t } = useTranslation();

  const [isDisplayingVideo, setIsDisplayingVideo] = useState<boolean>(false);

  return (
    <div style={props.style}>
      {isDisplayingVideo ? (
        <Modal
          className='openTutoContainer'
          open={isDisplayingVideo}
          onClose={() => setIsDisplayingVideo(false)}
          // to avoid mui adding useless padding to body
          disableScrollLock={true}
        >
          <>
            <ReactPlayer
              controls={true}
              playing={true}
              url={VideoTuto}
              width={'auto'}
              height={'65vh'}
              style={{
                border: 'solid',
                borderWidth: '20px',
                borderRadius: '10px',
                borderBottomColor: BrandColor,
                borderTopColor: BrandColor,
                borderLeftColor: BrandColor,
                borderRightColor: BrandColor,
              }}
            />

            <img
              src={CloseTag}
              alt='voir le tuto vidéo'
              style={{ position: 'absolute', right: 30, width: 55, cursor: 'pointer' }}
              onClick={() => setIsDisplayingVideo(false)}
            />
          </>
        </Modal>
      ) : (
        <div className='closeTutoContainer'>
          <p className='textNeedHelp'>{t('need-help')}</p>

          <img
            src={OpenTag}
            alt='fermer le tuto vidéo'
            style={{ cursor: 'pointer', width: 55 }}
            onClick={() => setIsDisplayingVideo(true)}
          />
        </div>
      )}
    </div>
  );
}
