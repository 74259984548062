import React from 'react';
import { TextField } from '@mui/material';
import { styled } from '@mui/system';
import { BrandColor } from '../helper/colorHelper';

interface TextInputProps {
  onChange?: (arg?: any) => void;
  onKeyPress?: (arg?: any) => void;
  onBlur?: (arg?: any) => void;
  name?: string;
  label?: string;
  placeholder?: string;
  width?: string;
  isRequired?: boolean;
  isMultiline?: boolean;
  isDisabled?: boolean;
  value?: any;
  type?: string;
  ref?: any;
  color?: string;
  border?: boolean;
  // sans maj -> les attributs dispo en html type min, max, autocomplete ...
  inputProps?: any;
  // avec maj -> les attributs propres à MUI comme un picto password
  InputProps?: any;
}

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    backgroundColor: 'white',
    borderRadius: 5,
    fontFamily: 'RobotoRegular',
    fontSize: 14,
    margin: 0,
  },
  '& .Mui-disabled': {
    opacity: 0.8,
    ' -webkit-text-fill-color': 'black',
  },
  '& label.Mui-focused': {
    color: 'black',
  },
  label: {
    fontSize: 14,
  },
});

export default function MainInput(props: TextInputProps) {
  const urlPattern = () => {
    const urlValidation = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
    if (urlValidation.test(props.value) === true || !props.value) {
      return true;
    } else return false;
  };

  return (
    <div className='inputLogIn'>
      <StyledTextField
        required={props.isRequired ? props.isRequired : false}
        disabled={props.isDisabled ? props.isDisabled : false}
        type={props.type ? props.type : 'text'}
        onChange={props.onChange}
        onKeyPress={props.onKeyPress}
        onBlur={props.onBlur}
        label={props.label}
        placeholder={props.placeholder ? props.placeholder : props.label}
        name={props.name}
        // ne pas supprimer merci, sert au controle de la date
        inputProps={props.inputProps}
        InputProps={props.InputProps}
        sx={{
          width: props.width ? props.width : 400,
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: props.color && props.border ? props.color : 'black',
            },
            '&:hover fieldset': {
              borderColor: props.color ? props.color : BrandColor,
            },
            '&.Mui-focused fieldset': {
              borderColor: props.color ? props.color : BrandColor,
            },
          },
        }}
        multiline={props.isMultiline ? props.isMultiline : false}
        value={props.value}
        inputRef={props.ref}
        onErrorCapture={(e: any) => console.log(e)}
        margin='dense'
        size='small'
        error={props.type === 'url' && !urlPattern() ? true : false}
        helperText={props.type === 'url' && !urlPattern() ? 'Veuillez saisir une adresse conforme' : null}
      />
    </div>
  );
}
