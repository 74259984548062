import { PlacementTypeEnum, OptionBroadcastTime } from '../models/ad';
import { Coupon, CouponType } from '../models/coupon';

export const roundNumber = (number: number) => {
  return Math.round(number * 100) / 100;
};

export const getTTCPrice = (htPrice: number, tvaAmount?: number): number => {
  // tva 20% by default
  const tva = tvaAmount ? tvaAmount : 1.2;
  return roundNumber(htPrice * tva);
};

export const getHTPrice = (ttcPrice: number): number => {
  // for tva 20%
  return roundNumber(ttcPrice / 1.2);
};

export const getPriceWithCoupon = (initialPrice: number, coupon: Coupon): number => {
  let finalPrice = 0;
  const amount = coupon.discountAmount || 0;

  switch (coupon.type) {
    case CouponType.FIXED:
      amount < initialPrice ? (finalPrice = initialPrice - amount) : (finalPrice = 0);
      break;
    // case CouponType.PERCENTAGE:
    //   amount > 1
    //     ? (finalPrice = Math.max(0, initialPrice - (initialPrice * amount) / 100))
    //     : amount > 0
    //     ? (finalPrice = Math.max(initialPrice - initialPrice * amount))
    //     : (finalPrice = initialPrice);
    //   break;
  }
  return finalPrice;
};

const getPricePerThousand = (placementType: PlacementTypeEnum) => {
  // Price for 1000 views
  let pricePerThousand = 0;
  if (placementType === PlacementTypeEnum.STATISTICS_MEDIA) {
    pricePerThousand = 8;
  } else if (placementType === PlacementTypeEnum.PERMANENT_BANNER) {
    pricePerThousand = 7;
  } else if (placementType === PlacementTypeEnum.INTERSTITIAL) {
    pricePerThousand = 10;
  }
  return pricePerThousand;
};

export const getViewsWithBudget = (placementType: PlacementTypeEnum, budget: number) => {
  const views = (budget / getPricePerThousand(placementType)) * 1000;
  // if 12.95 view, rounded to 12 views
  return Math.floor(views);
};

export const getMaxTpBudgetWithViews = (placementType: PlacementTypeEnum, views: number) => {
  const adPrice = (getPricePerThousand(placementType) * views) / 1000;
  return Math.floor(adPrice);
};

export const formateCurrency = (price: number, currency: 'EUR' | 'USD' = 'EUR') => {
  return new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: currency,
  }).format(price);
};
