import React, { useState } from 'react';
import './CampaignOverview.scss';
import AdOverview from '../component/AdOverview';
import { useTranslation } from 'react-i18next';
import { IAdAPI, IAdRedux, MediaType, ValidationEnum } from '../models/ad';
import { PaymentStatus } from '../models/campaign';
import { dateFormatDDMMAA } from '../helper/dateHelper';
import MainModal from './MainModal';
import MainButton from './MainButton';
import warning from './../assets/icons/warning.svg';
import valid from './../assets/icons/valid.svg';
import invalid from './../assets/icons/invalid.svg';
import play from './../assets/icons/play.svg';
import pause from './../assets/icons/pause.svg';
import { BrandColor } from '../helper/colorHelper';

interface CampaignOverviewProps {
  campaignName: string;
  lastUpdateDate?: string;
  creationDate?: string;
  beginDate?: string;
  closedDate?: string;
  adDataLocal?: IAdRedux[];
  adDataApi?: IAdAPI[];
  onClickCard?: () => void;
  onDeleteCampaign?: () => void;
  paymentStatus?: PaymentStatus;
  moderationStatus?: ValidationEnum;
}

function CampaignOverview(props: CampaignOverviewProps) {
  const { t } = useTranslation();
  const today = new Date();

  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <article className='campaignCard'>
      <MainModal isOpen={showModal} handleClose={() => setShowModal(false)}>
        <p className='titleStep'>
          {t('delete-campaign')} {props.campaignName}
        </p>
        <MainButton
          label={t('delete')}
          backgroundColor={'red'}
          onClickButton={() => {
            props.onDeleteCampaign && props.onDeleteCampaign();
            setShowModal(false);
          }}
        />
        <MainButton label={t('cancel')} backgroundColor={'grey'} onClickButton={() => setShowModal(false)} />
      </MainModal>
      <h3>{props.campaignName}</h3>
      <button type='button' className='campaignPreview' onClick={props.onClickCard}>
        {props.adDataLocal ? (
          <div className='adPreview'>
            {props.adDataLocal &&
              props.adDataLocal.map((overview: IAdRedux, index: number) => (
                <AdOverview
                  key={index}
                  image={overview.media}
                  partner={overview.partner}
                  placementType={overview.placementType}
                  isVideo={overview.media_type === MediaType.VIDEO}
                  width={
                    overview.media_type === MediaType.VIDEO || overview.placementType === 'livefeed_media' ? 140 : 70
                  }
                />
              ))}
          </div>
        ) : (
          <div className='adPreview'>
            {props.adDataApi &&
              props.adDataApi.map((overview: IAdAPI, index: number) => {
                return (
                  <AdOverview
                    key={index}
                    image={
                      (process.env.REACT_APP_BACKEND_ROOT_URL || (window as any)._env_.REACT_APP_BACKEND_ROOT_URL) +
                      overview.ad.uploadedMediaUrl
                    }
                    partner={overview.ad.partner}
                    placementType={overview.ad.placementType}
                    isVideo={overview.ad.uploadedMediaType === MediaType.VIDEO}
                    width={
                      overview.ad.uploadedMediaType === MediaType.VIDEO ||
                      overview.ad.placementType === 'livefeed_media'
                        ? 140
                        : 70
                    }
                  />
                );
              })}
          </div>
        )}
      </button>
      {props.lastUpdateDate && <p className='sub'>{t('last-update') + ' ' + props.lastUpdateDate}</p>}

      {props.creationDate && (
        <p className='sub'>
          {t('creation-date')} {dateFormatDDMMAA(new Date(props.creationDate))}
        </p>
      )}

      {props.beginDate && (
        <p className='sub'>
          {t('begin-date')} {dateFormatDDMMAA(new Date(props.beginDate))}
        </p>
      )}

      {props.closedDate && (
        <p className='sub'>
          {t('closing-date')} {dateFormatDDMMAA(new Date(props.closedDate))}
        </p>
      )}

      {props.moderationStatus === ValidationEnum.ACCEPTED ? (
        <div className='sub'>
          <img src={valid} alt='valid' />
          <p style={{ color: BrandColor }}>{t('mod-status.approved')}</p>
        </div>
      ) : props.moderationStatus === ValidationEnum.PENDING || props.moderationStatus === ValidationEnum.STANDING_BY ? (
        <div className='sub'>
          <img src={warning} alt='warning' />
          <p style={{ color: 'orange' }}>{t('mod-status.pending')}</p>
        </div>
      ) : props.moderationStatus === ValidationEnum.REFUSED ? (
        <div className='sub'>
          <img src={invalid} alt='rejected' />
          <p style={{ color: 'red' }}>{t('mod-status.rejected')}</p>
        </div>
      ) : null}

      {props.paymentStatus && (
        <div className='sub'>
          {props.paymentStatus === PaymentStatus.PENDING ? (
            <img src={warning} alt='warning' />
          ) : (
            <img src={invalid} alt='rejected' />
          )}
          {props.paymentStatus === PaymentStatus.PENDING ? (
            <p style={{ color: 'orange' }}>{t('paiement-status.pending')}</p>
          ) : (
            <p style={{ color: 'red' }}>{t('paiement-status.rejected')}</p>
          )}
        </div>
      )}

      {props.beginDate && (
        <div className='sub'>
          {new Date(props.beginDate) > today ? <img src={pause} alt='pause' /> : <img src={play} alt='play' />}
          <p style={{ color: BrandColor }}>{new Date(props.beginDate) > today ? t('waiting') : t('active')}</p>
        </div>
      )}

      {props.closedDate && (
        <div className='sub'>
          <img src={valid} alt='valid' />
          <p style={{ color: BrandColor }}>{t('end')}</p>
        </div>
      )}

      <div className='campaignButtonContainer'>
        {props.adDataLocal && (
          <MainButton
            label={t('delete')}
            backgroundColor={'red'}
            onClickButton={() => setShowModal(true)}
            height={30}
            width={100}
          />
        )}
      </div>
    </article>
  );
}

export default CampaignOverview;
