import axios from 'axios';
import { ITokenRedux } from '../slice/TokenSlice';

export async function getCampaignFile(id: string | undefined, fileName: string) {
  const token: ITokenRedux = JSON.parse(localStorage.token);
  const campaignFile = await axios
    .get(
      `${
        process.env.REACT_APP_BACKEND_ROOT_URL || (window as any)._env_.REACT_APP_BACKEND_ROOT_URL
      }/api/campaign/${id}/download?file=${fileName}`,
      {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY || (window as any)._env_.REACT_APP_API_KEY || '',
          Authorization: 'Bearer ' + token.access,
        },
        responseType: 'blob',
      },
    )
    .then(async (res) => {
      const href = window.URL.createObjectURL(res.data);
      const anchorElement = document.createElement('a');
      anchorElement.href = href;
      anchorElement.download = res['headers']['x-suggested-filename'];
      document.body.appendChild(anchorElement);
      anchorElement.click();
      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(href);
      return { status: 200 };
    })
    .catch(() => {
      return { status: 404 };
    });
  return campaignFile;
}
