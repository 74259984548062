import React, { useState, useEffect } from 'react';
import './UserCampaigns.scss';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetCampaignsQuery } from '../services/CampaignApi';
import { copyCampaignFromStorage, copyCampaignFromAPI, createCampaign, resetCampaign } from '../slice/CampaignSlice';
import {
  deleteCampaignByIndexToLocalStorage,
  getCampaignByIndexFromLocalStorage,
  getCampaignsFromLocalStorage,
} from '../helper/localStorage/localStorageCampaignHelper';
import { ICampaignRedux, ICampaignFetchAPI } from '../models/campaign';
import LayoutPrincipal from '../component/LayoutPrincipal';
import CampaignOverview from '../component/CampaignOverview';
import MainButton from '../component/MainButton';
import MainInput from '../component/MainInput';
import MainModal from '../component/MainModal';
import AddButton from '../component/AddButton';
import { useTranslation } from 'react-i18next';
import { removeCurrentCampaignToLocalStorage } from '../helper/localStorage/localStorageCurrentCampaign';
import { minEndDate_calculation } from '../helper/dateHelper';

function UserCampaigns() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const campaignQuery = useGetCampaignsQuery();
  const listCampaignsPending = campaignQuery.isSuccess && campaignQuery.data && campaignQuery.data.pending;
  const listCampaigns = campaignQuery.isSuccess && campaignQuery.data && campaignQuery.data.paid;
  const listCampaignsClosed = campaignQuery.isSuccess && campaignQuery.data && campaignQuery.data.closed;

  const [showModalNew, setShowModalNew] = useState<boolean>(false);
  const [newCampaignError, setNewCampaignError] = useState<string>();
  const [campaignsInCreation, setCampaignsInCreation] = useState<ICampaignRedux[]>(getCampaignsFromLocalStorage());

  const [newCampaign, setNewCampaign] = useState<{
    name: string;
    startDatetime: string;
    endDatetime: string;
  }>({
    name: '',
    startDatetime: '',
    endDatetime: '',
  });

  const today = new Date();
  // today + 3 days, formatted in ISO for the input date options.
  // toISOString convert the date to UTC which can change the day, so we use Date.UTC to force to have the good day.
  const minDate = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate() + 3))
    .toISOString()
    .slice(0, 10);

  const verifyNewCampaign = () => {
    if (!newCampaign.name || !newCampaign.startDatetime) {
      return t('error.general-form');
    } else {
      return 'valid';
    }
  };

  function createNewCampaign(e: React.SyntheticEvent) {
    e.preventDefault();
    if (verifyNewCampaign() === 'valid') {
      dispatch(
        createCampaign({
          name: newCampaign.name,
          startDatetime: newCampaign.startDatetime,
          endDatetime: newCampaign.endDatetime ? newCampaign.endDatetime : undefined,
        }),
      );
      navigate('/campaign-details');
    } else {
      setNewCampaignError(verifyNewCampaign());
    }
  }

  useEffect(() => {
    dispatch(resetCampaign());
    removeCurrentCampaignToLocalStorage();
  }, [dispatch]);

  return (
    <LayoutPrincipal
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
      }}
      titleStep={t('title.campaign-in-creation')}
      isShowingVideoTuto={true}
    >
      <MainModal
        isOpen={showModalNew}
        handleClose={() => {
          setShowModalNew(false);
        }}
      >
        <p className='titleStep'>{t('create-campaign')}</p>
        <p style={{ marginTop: '-5%', color: 'red' }}>(Durée minimum 30 jours)</p>
        <form className='newCampaignForm' onSubmit={createNewCampaign}>
          <MainInput
            width={'380px'}
            name='campaign name'
            label={t('name')}
            inputProps={{
              maxlength: '50',
            }}
            value={newCampaign.name}
            onChange={(e) => {
              setNewCampaign({ ...newCampaign, name: e.target.value });
            }}
          />
          <section>
            <p>{t('start-date-time')} *</p>
            <MainInput
              type={'date'}
              width={'180px'}
              inputProps={{ min: minDate }}
              value={newCampaign.startDatetime}
              onChange={(e) => {
                setNewCampaign((prevNewCampaign) => {
                  const newStartDateTime = e.target.value;
                  return {
                    ...prevNewCampaign,
                    startDatetime: newStartDateTime,
                    endDatetime:
                      prevNewCampaign.endDatetime &&
                      prevNewCampaign.endDatetime <
                        minEndDate_calculation(newStartDateTime, newCampaign.startDatetime, minDate)
                        ? minEndDate_calculation(newStartDateTime, newCampaign.startDatetime, minDate)
                        : prevNewCampaign.endDatetime,
                  };
                });
              }}
            />
          </section>
          <section>
            <p>{t('end-date-time')}</p>
            <MainInput
              type={'date'}
              width={'180px'}
              inputProps={{ min: minEndDate_calculation(undefined, newCampaign.startDatetime, minDate) }}
              value={newCampaign.endDatetime}
              onChange={(e) => {
                setNewCampaign((prevNewCampaign) => ({
                  ...prevNewCampaign,
                  endDatetime: e.target.value,
                }));
                setNewCampaignError('');
              }}
            />
          </section>

          {!newCampaign.name || !newCampaign.startDatetime || newCampaignError ? (
            <div>
              <MainButton label={t('validation')} backgroundColor={'grey'} disabled={true} />
              <p className='errorCampaign'>{newCampaignError}</p>
            </div>
          ) : (
            <MainButton label={t('validation')} />
          )}
        </form>
      </MainModal>

      <section className='campaignList'>
        {campaignsInCreation && campaignsInCreation.length > 0 ? (
          campaignsInCreation
            .map((campaign: ICampaignRedux, index: number) => {
              return (
                <CampaignOverview
                  key={index}
                  campaignName={campaign.name}
                  lastUpdateDate={campaign.lastUpdate}
                  adDataLocal={campaign.ads}
                  onClickCard={() => {
                    dispatch(copyCampaignFromStorage(getCampaignByIndexFromLocalStorage(index)));
                    navigate('/campaign-details');
                  }}
                  onDeleteCampaign={() => {
                    deleteCampaignByIndexToLocalStorage(index);
                    setCampaignsInCreation(getCampaignsFromLocalStorage());
                  }}
                />
              );
            })
            .reverse()
        ) : (
          <p className='emptyList'>{t('no-campaign')}</p>
        )}
        <div style={{ marginLeft: '20px', marginTop: '15px' }}>
          <AddButton label={t('new-campaign')} onClickAddButton={() => setShowModalNew(true)} />
        </div>
      </section>

      {listCampaignsPending && listCampaignsPending.length > 0 && (
        <>
          <p className='titleStep' style={{ marginTop: '50px', marginBottom: '5px' }}>
            {t('title.campaign-pending')}
          </p>
          <section className='campaignList'>
            {listCampaignsPending.map((campaign: ICampaignFetchAPI, index: number) => {
              return (
                <CampaignOverview
                  key={index}
                  campaignName={campaign.name}
                  creationDate={campaign.createdAt}
                  adDataApi={campaign.ads}
                  paymentStatus={campaign.paymentStatus}
                  onClickCard={() => {
                    dispatch(copyCampaignFromAPI(campaign));
                    navigate('/campaign-details');
                  }}
                />
              );
            })}
          </section>
        </>
      )}

      {listCampaigns && listCampaigns.length > 0 && (
        <>
          <p className='titleStep' style={{ marginTop: '50px', marginBottom: '5px' }}>
            {t('title.campaign-validated')}
          </p>
          <section className='campaignList'>
            {listCampaigns.map((campaign: ICampaignFetchAPI, index: number) => {
              return (
                <CampaignOverview
                  key={index}
                  campaignName={campaign.name}
                  beginDate={campaign.startDatetime}
                  adDataApi={campaign.ads}
                  // moderationStatus={
                  //   campaign.ads.every((ad) => ad.ad.validation === ValidationEnum.ACCEPTED)
                  //     ? ValidationEnum.ACCEPTED
                  //     : campaign.ads.every((ad) => ad.ad.validation === ValidationEnum.REFUSED)
                  //     ? ValidationEnum.REFUSED
                  //     : ValidationEnum.PENDING
                  // }
                  onClickCard={() => {
                    dispatch(copyCampaignFromAPI(campaign));
                    navigate('/campaign-details');
                  }}
                />
              );
            })}
          </section>
        </>
      )}

      {listCampaignsClosed && listCampaignsClosed.length > 0 && (
        <>
          <p className='titleStep' style={{ marginTop: '50px', marginBottom: '5px' }}>
            {t('title.campaign-closed')}
          </p>
          <section className='campaignList'>
            {listCampaignsClosed.map((campaign: ICampaignFetchAPI, index: number) => {
              return (
                <CampaignOverview
                  key={index}
                  campaignName={campaign.name}
                  closedDate={campaign.closedAt}
                  adDataApi={campaign.ads}
                  onClickCard={() => {
                    dispatch(copyCampaignFromAPI(campaign));
                    navigate('/campaign-results');
                  }}
                />
              );
            })}
          </section>
        </>
      )}
    </LayoutPrincipal>
  );
}

export default UserCampaigns;
