import { ICampaignRedux } from '../../models/campaign';

export function registerCampaignToLocalStorgae(currentCampaign: ICampaignRedux) {
  const allCampaignsfromStorage = JSON.parse(localStorage.getItem('campaign') + '');

  if (allCampaignsfromStorage && allCampaignsfromStorage.length > 0) {
    const index = allCampaignsfromStorage.findIndex(
      (campaign: ICampaignRedux) => campaign.name === currentCampaign.name,
    );
    if (index != -1) {
      allCampaignsfromStorage[index] = currentCampaign;
      localStorage.setItem('campaign', JSON.stringify(allCampaignsfromStorage));
    } else {
      localStorage.setItem('campaign', JSON.stringify(allCampaignsfromStorage.concat([currentCampaign])));
    }
  } else {
    localStorage.setItem('campaign', JSON.stringify([currentCampaign]));
  }
}

export function getCampaignsFromLocalStorage() {
  return JSON.parse(localStorage.getItem('campaign') + '');
}

export function getCampaignByIndexFromLocalStorage(currentIndex: number) {
  const allCampaignsfromStorage = JSON.parse(localStorage.getItem('campaign') + '');
  return allCampaignsfromStorage[currentIndex];
}

export function deleteCampaignByIndexToLocalStorage(currentIndex: number) {
  const allCampaignsfromStorage = JSON.parse(localStorage.getItem('campaign') + '');
  allCampaignsfromStorage.splice(currentIndex, 1);
  return localStorage.setItem('campaign', JSON.stringify(allCampaignsfromStorage));
}

export function deleteCampaignByNameToLocalStorage(currentName: string) {
  const allCampaignsfromStorage = JSON.parse(localStorage.getItem('campaign') + '');
  if (allCampaignsfromStorage && allCampaignsfromStorage.length > 0) {
    const index = allCampaignsfromStorage.findIndex((campaign: ICampaignRedux) => campaign.name === currentName);
    if (index != -1) {
      allCampaignsfromStorage.splice(index, 1);
      localStorage.setItem('campaign', JSON.stringify(allCampaignsfromStorage));
    }
  }
}
