import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Sport } from '../models/sport';

export interface TpInventaryArgs {
  sports?: string;
  postcodes?: string;
  counties?: string;
  ages?: string;
}

interface inventaryPeriod {
  daily: number;
  weekly: number;
  monthly: number;
}

export interface TpInventary {
  active_unique_users: inventaryPeriod;
  sessions: inventaryPeriod;
  ads_impressions: inventaryPeriod;
  interstitials_impressions: inventaryPeriod;
  mrec_impressions: inventaryPeriod;
  banners_impressions: inventaryPeriod;
}

// swagger  https://misc-api.teampulse.net/swagger#/Main%20endpoint/get_api_ads_analytics
export const tpInventaryApi = createApi({
  reducerPath: 'tpInventaryApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.champs.pub/teampulse/api/',
    prepareHeaders: (headers) => {
      headers.set('X-TeamPulse-Access-Token', 'champs_yS3MRJsRGriBOkzkS3Ajrg64Ny2wcRr9gzT5uQekhLM');
    },
  }),
  endpoints: (builder) => ({
    getTpSports: builder.query<Sport[], null>({
      query: () => '/sports',
      transformResponse: (resp: Sport[]) => {
        // sort alphabetically
        const sports = resp.sort(function (sportA, sportB) {
          return sportA.name === sportB.name ? 0 : sportA.name < sportB.name ? -1 : 1;
        });
        return sports;
      },
    }),
    getTpInventary: builder.query<TpInventary, TpInventaryArgs>({
      // endpoint exemple analytics?sport_ids=1,2&town_ids=1&postcodes=01480,01360&counties=01&age=from15to18'
      query: (args) =>
        `/analytics?${args.sports ? 'sport_ids=' + args.sports + '&' : ''}${
          args.postcodes ? 'postcodes=' + args.postcodes + '&' : ''
        }${args.counties ? 'counties=' + args.counties + '&' : ''}${args.ages && 'player_age=' + args.ages}`,
    }),
  }),
});

export const { useGetTpSportsQuery, useGetTpInventaryQuery } = tpInventaryApi;
export const { endpoints, reducerPath, reducer, middleware } = tpInventaryApi;
