import { createSlice } from '@reduxjs/toolkit';
import { ICampaignFetchAPI, ICampaignRedux } from '../models/campaign';
import {
  IAdRedux,
  MediaType,
  PartnerEnum,
  PlacementTypeEnum,
  SexeEnum,
  OptionBroadcastTime,
  IAdAPI,
  Age,
} from '../models/ad';
import { registerCurrentCampaignToLocalStorage } from '../helper/localStorage/localStorageCurrentCampaign';
import IRule from '../models/rule';
import { dateFormatDDMMAA } from '../helper/dateHelper';
import { Coupon } from '../models/coupon';
import { Sport } from '../models/sport';

const initialState: ICampaignRedux = JSON.parse(localStorage.getItem('currentCampaign') + '') || {
  name: '',
  currentAdId: 1,
  ads: [],
  coupons: [],
  lastUpdate: dateFormatDDMMAA(new Date()),
};

export const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    createCampaign: (
      state,
      action: {
        payload: { name: string; startDatetime: string; endDatetime?: string };
      },
    ) => {
      return (state = {
        lastUpdate: dateFormatDDMMAA(new Date()),
        currentAdId: 1,
        ads: [],
        coupons: [],
        name: action.payload.name,
        startDatetime: action.payload.startDatetime,
        ...(action.payload.endDatetime ? { endDatetime: action.payload.endDatetime } : {}),
        creditBalanceUsed: 0,
      });
    },
    copyCampaignFromStorage: (state, action: { payload: ICampaignRedux }) => {
      registerCurrentCampaignToLocalStorage(action.payload);
      return (state = action.payload);
    },
    createNewAd: (state, action: { payload: PartnerEnum }) => {
      const ids = state.ads.map((ad: IAdRedux) => {
        return ad.id;
      });
      state.ads.push({
        id: ids && ids.length > 0 ? Math.max(...ids) + 1 : 1,
        public: {
          sexe: SexeEnum.ANY,
        },
        partner: action.payload,
        billPriceExcl: 0,
      });
      state.currentAdId = ids && ids.length > 0 ? Math.max(...ids) + 1 : 1;
      state.lastUpdate = dateFormatDDMMAA(new Date());
    },
    dupplicateNewAd: (state, action: { payload: number }) => {
      const findAdToDupplicate = state.ads.find((ad: IAdRedux) => ad.id === action.payload);
      if (findAdToDupplicate) {
        const ids = state.ads.map((ad: IAdRedux) => {
          return ad.id;
        });

        state.ads.push({
          ...findAdToDupplicate,
          idApi: undefined,
          id: ids && ids.length > 0 ? Math.max(...ids) + 1 : 1,
        });
        state.currentAdId = ids && ids.length > 0 ? Math.max(...ids) + 1 : 1;
        state.lastUpdate = dateFormatDDMMAA(new Date());
      }
      registerCurrentCampaignToLocalStorage(state);
    },
    resetCampaign: (state) => {
      return (state = {
        name: '',
        lastUpdate: dateFormatDDMMAA(new Date()),
        startDatetime: '',
        currentAdId: 1,
        ads: [],
        coupons: [],
        creditBalanceUsed: 0,
      });
    },
    recoverType: (state, action: { payload: PlacementTypeEnum }) => {
      if (state.ads && state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId).length === 1) {
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId)[0].placementType = action.payload;
        state.lastUpdate = dateFormatDDMMAA(new Date());
      }
      registerCurrentCampaignToLocalStorage(state);
    },
    recoverMedia: (state, action: { payload: { id: string; media: string; mediaType: MediaType } }) => {
      if (
        state.ads &&
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId).length === 1 &&
        action.payload.media
      ) {
        const baseUrl = process.env.REACT_APP_BACKEND_ROOT_URL || (window as any)._env_.REACT_APP_BACKEND_ROOT_URL;
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId)[0].media_id = action.payload.id;
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId)[0].media_type = action.payload.mediaType;
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId)[0].media = `${baseUrl}${action.payload.media}`;
        state.lastUpdate = dateFormatDDMMAA(new Date());
      }
      registerCurrentCampaignToLocalStorage(state);
    },
    recoverBillPriceExcl(state, action: { payload: number }) {
      if (state.ads && state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId).length === 1) {
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId)[0].billPriceExcl = action.payload;
        state.lastUpdate = dateFormatDDMMAA(new Date());
      }
      registerCurrentCampaignToLocalStorage(state);
    },
    recoverBroadcastTimeMatch(state, action: { payload: OptionBroadcastTime[] }) {
      if (state.ads && state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId).length === 1) {
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId)[0].public.time_match = action.payload;
        state.lastUpdate = dateFormatDDMMAA(new Date());
      }
      registerCurrentCampaignToLocalStorage(state);
    },
    recoverDestUrl(state, action: { payload: string }) {
      if (state.ads && state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId).length === 1) {
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId)[0].dest_url = action.payload;
        state.lastUpdate = dateFormatDDMMAA(new Date());
      }
      registerCurrentCampaignToLocalStorage(state);
    },
    recoverCreatedWithAmbassador(state, action: { payload: boolean }) {
      if (state.ads && state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId).length === 1) {
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId)[0].created_with_ambassador = action.payload;
        state.lastUpdate = dateFormatDDMMAA(new Date());
      }
      registerCurrentCampaignToLocalStorage(state);
    },
    recoverChampionship(state, action: { payload: string[] }) {
      if (
        state.ads &&
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId).length === 1 &&
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId)[0].public
      ) {
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId)[0].public.championships = action.payload;
        state.lastUpdate = dateFormatDDMMAA(new Date());
      }
    },
    recoverSports(state, action: { payload: Sport[] }) {
      if (
        state.ads &&
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId).length === 1 &&
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId)[0].public
      ) {
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId)[0].public.sports = action.payload;
        state.lastUpdate = dateFormatDDMMAA(new Date());
        registerCurrentCampaignToLocalStorage(state);
      }
    },
    recoverClubs(state, action: { payload: string[] }) {
      if (
        state.ads &&
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId).length === 1 &&
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId)[0].public
      ) {
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId)[0].public.clubs = action.payload;
        state.lastUpdate = dateFormatDDMMAA(new Date());
        registerCurrentCampaignToLocalStorage(state);
      }
    },
    recoverMatchs(state, action: { payload: string[] }) {
      if (
        state.ads &&
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId).length === 1 &&
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId)[0].public
      ) {
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId)[0].public.matchs = action.payload;
        state.lastUpdate = dateFormatDDMMAA(new Date());
        registerCurrentCampaignToLocalStorage(state);
      }
    },
    recoverZipcodes(state, action: { payload: string[] }) {
      if (
        state.ads &&
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId).length === 1 &&
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId)[0].public
      ) {
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId)[0].public.zipcodes = action.payload;
        state.lastUpdate = dateFormatDDMMAA(new Date());
        registerCurrentCampaignToLocalStorage(state);
      }
    },
    recoverDpt(state, action: { payload: string[] }) {
      if (
        state.ads &&
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId).length === 1 &&
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId)[0].public
      ) {
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId)[0].public.departments = action.payload;
        state.lastUpdate = dateFormatDDMMAA(new Date());
        registerCurrentCampaignToLocalStorage(state);
      }
    },
    recoverAge(state, action: { payload: Age[] }) {
      if (
        state.ads &&
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId).length === 1 &&
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId)[0].public
      ) {
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId)[0].public.age = action.payload;
        state.lastUpdate = dateFormatDDMMAA(new Date());
      }
      registerCurrentCampaignToLocalStorage(state);
    },
    recoverSexe(state, action: { payload: SexeEnum }) {
      if (state.ads && state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId).length === 1) {
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId)[0].public.sexe = action.payload;
        state.lastUpdate = dateFormatDDMMAA(new Date());
        registerCurrentCampaignToLocalStorage(state);
      }
    },
    recoverIsCloseToMatch(state, action: { payload: boolean }) {
      if (
        state.ads &&
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId).length === 1 &&
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId)[0].public
      ) {
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId)[0].public.close_to_match = action.payload;
        state.lastUpdate = dateFormatDDMMAA(new Date());
      }
    },
    recoverIsPratciceCurrentSport(state, action: { payload: boolean }) {
      if (
        state.ads &&
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId).length === 1 &&
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId)[0].public
      ) {
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId)[0].public.practice_current_sport =
          action.payload;
        state.lastUpdate = dateFormatDDMMAA(new Date());
      }
    },
    recoverIsBearLocalClub(state, action: { payload: boolean }) {
      if (
        state.ads &&
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId).length === 1 &&
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId)[0].public
      ) {
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId)[0].public.bear_local_club = action.payload;
        state.lastUpdate = dateFormatDDMMAA(new Date());
      }
    },
    recoverIsBearVisitorClub(state, action: { payload: boolean }) {
      if (
        state.ads &&
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId).length === 1 &&
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId)[0].public
      ) {
        state.ads.filter((ad: IAdRedux) => ad.id === state.currentAdId)[0].public.bear_visitor_club = action.payload;
        state.lastUpdate = dateFormatDDMMAA(new Date());
      }
    },
    changeAd(state, action: { payload: number }) {
      state.currentAdId = action.payload;
      state.lastUpdate = dateFormatDDMMAA(new Date());
      registerCurrentCampaignToLocalStorage(state);
    },
    deleteAd(state, action: { payload: number }) {
      if (state.ads && state.ads.find((ad: IAdRedux) => ad.id === action.payload)) {
        state.ads = state.ads.filter((ad: IAdRedux) => ad.id != action.payload);

        // If a coupon exists and there are no more ads for the partner of the coupon, then we delete the coupon
        state.coupons = state.coupons.filter(
          (coupon: Coupon) => !!state.ads.find((ad: IAdRedux) => ad.partner === coupon.distributionPartner),
        );

        registerCurrentCampaignToLocalStorage(state);
      }
    },
    copyCampaignFromAPI(state, action: { payload: ICampaignFetchAPI }) {
      state.isCampaignFromAPI = true;
      state.name = action.payload.name;
      state.idApi = action.payload.id;
      state.lastUpdate = action.payload.createdAt;
      state.startDatetime = action.payload.startDatetime;
      state.endDatetime = action.payload.endDatetime;
      state.closedAt = action.payload.closedAt;
      state.paymentStatus = action.payload.paymentStatus;
      state.coupons = action.payload.coupons;
      state.creditBalanceUsed = action.payload.creditBalanceUsed;
      state.creditBalanceEarned = action.payload.creditBalanceEarned;
      state.reducedBillPriceExcl = action.payload.reducedBillPriceExcl;
      state.reducedBillPriceIncl = action.payload.reducedBillPriceIncl;
      state.invoice = action.payload.invoice;
      state.orderForm = action.payload.orderForm;
      state.creditNote = action.payload.creditNote;
      state.deliveryNote = action.payload.deliveryNote;

      state.ads = action.payload.ads.map((adArray: IAdAPI, index: number) => {
        let newStringAge = '';
        if (adArray.ad.audience.simpleRuleSet.filter((rule: IRule) => rule.ruleType === 'age_range').length > 0) {
          adArray.ad.audience.simpleRuleSet
            .filter((rule: IRule) => rule.ruleType === 'age_range')
            .map((ruleType: IRule, index: number) => {
              newStringAge =
                index === 0
                  ? ruleType.ageMin && ruleType.ageMin < 60
                    ? newStringAge + ruleType.ageMin + '-' + ruleType.ageMax
                    : newStringAge + '> ' + ruleType.ageMin
                  : ruleType.ageMin && ruleType.ageMin < 60
                  ? newStringAge + ',' + ruleType.ageMin + '-' + ruleType.ageMax
                  : newStringAge + ',' + '> ' + ruleType.ageMin;
            });
        }

        const timeMatchArray: OptionBroadcastTime[] = [];
        if (
          adArray.ad.audience.simpleRuleSet.filter((rule: IRule) => rule.ruleType === 'time_match') &&
          adArray.ad.audience.simpleRuleSet.filter((rule: IRule) => rule.ruleType === 'time_match').length > 0
        ) {
          adArray.ad.audience.simpleRuleSet
            .filter((rule: IRule) => rule.ruleType === 'time_match')
            .map((ruleType: IRule) => {
              ruleType.timeMatch && timeMatchArray.push(ruleType.timeMatch);
            });
        }

        const matchArray: string[] = [];
        if (
          adArray.ad.audience.simpleRuleSet.filter((rule: IRule) => rule.ruleType === 'match') &&
          adArray.ad.audience.simpleRuleSet.filter((rule: IRule) => rule.ruleType === 'match').length > 0
        ) {
          adArray.ad.audience.simpleRuleSet
            .filter((rule: IRule) => rule.ruleType === 'match')
            .map((ruleType: IRule) => {
              ruleType.matchId && matchArray.push(ruleType.matchId);
            });
        }

        const clubArray: string[] = [];
        if (
          adArray.ad.audience.simpleRuleSet.filter((rule: IRule) => rule.ruleType === 'club') &&
          adArray.ad.audience.simpleRuleSet.filter((rule: IRule) => rule.ruleType === 'club').length > 0
        ) {
          adArray.ad.audience.simpleRuleSet
            .filter((rule: IRule) => rule.ruleType === 'club')
            .map((ruleType: IRule) => {
              ruleType.clubId && clubArray.push(ruleType.clubId);
            });
        }

        const adObjectRedux: IAdRedux = {
          id: index,
          idApi: adArray.ad.id,
          placementType: adArray.ad.placementType,
          media_id: adArray.ad.uploadedMedia,
          media_type: adArray.ad.uploadedMediaType,
          media:
            (process.env.REACT_APP_BACKEND_ROOT_URL || (window as any)._env_.REACT_APP_BACKEND_ROOT_URL) +
            adArray.ad.uploadedMediaUrl,
          uploaded_media: adArray.ad.uploadedMedia,
          fixedQuantity: adArray.ad.fixedQuantity && adArray.ad.fixedQuantity,
          impressions: adArray.adStatistics && adArray.adStatistics.impressions,
          clics: adArray.adStatistics && adArray.adStatistics.clicks,
          billPriceExcl: adArray.ad.billPriceExcl,
          reducedBillPriceExcl: adArray.ad.reducedBillPriceExcl,
          reducedBillPriceExclConsumed: adArray.ad.reducedBillPriceExclConsumed,
          dest_url: adArray.ad.destUrl,
          partner: adArray.ad.partner,
          public: {
            ...(adArray.ad.audience.simpleRuleSet.find((rule: IRule) => rule.ruleType === 'gender')
              ? { sexe: adArray.ad.audience.simpleRuleSet.find((rule: IRule) => rule.ruleType === 'gender')?.gender }
              : {}),
          },
        };
        return adObjectRedux;
      });
      registerCurrentCampaignToLocalStorage(state);
    },

    recoverStartDateTime(state, action: { payload: string }) {
      state.startDatetime = action.payload;
      state.lastUpdate = dateFormatDDMMAA(new Date());
      registerCurrentCampaignToLocalStorage(state);
    },
    recoverEndDateTime(state, action: { payload: string | undefined }) {
      state.endDatetime = action.payload;
      state.lastUpdate = dateFormatDDMMAA(new Date());
      registerCurrentCampaignToLocalStorage(state);
    },

    recoverCoupon(state, action: { payload: Coupon }) {
      state.coupons.push(action.payload);
      state.lastUpdate = dateFormatDDMMAA(new Date());
      registerCurrentCampaignToLocalStorage(state);
    },

    deleteCoupon: (state, action: { payload: string }) => {
      state.coupons = state.coupons.filter((coupon: Coupon) => coupon.id !== action.payload);
      state.lastUpdate = dateFormatDDMMAA(new Date());
      registerCurrentCampaignToLocalStorage(state);
    },
  },
});

export const {
  createCampaign,
  recoverType,
  recoverMedia,
  recoverBillPriceExcl,
  recoverBroadcastTimeMatch,
  recoverDestUrl,
  recoverCreatedWithAmbassador,
  recoverSports,
  recoverChampionship,
  recoverClubs,
  recoverMatchs,
  recoverZipcodes,
  recoverDpt,
  recoverAge,
  recoverSexe,
  recoverIsBearLocalClub,
  recoverIsBearVisitorClub,
  recoverIsCloseToMatch,
  recoverIsPratciceCurrentSport,
  createNewAd,
  dupplicateNewAd,
  changeAd,
  recoverStartDateTime,
  recoverEndDateTime,
  recoverCoupon,
  deleteCoupon,
  resetCampaign,
  deleteAd,
  copyCampaignFromStorage,
  copyCampaignFromAPI,
} = campaignSlice.actions;

export default campaignSlice.reducer;
